// Arreglar focus (node_modules/bulma-scss/utilities/_controls.scss l. 34)

// .button:active,
// .button:focus {
//     @include focus;
// }
// .file-cta:active,
// .file-cta:focus,
// .file-name:active,
// .file-name:focus,
// .input:active,
// .input:focus,
// .is-active.button,
// .is-active.file-cta,
// .is-active.file-name,
// .is-active.input,
// .is-active.textarea,
// .is-focused.button,
// .is-focused.file-cta,
// .is-focused.file-name,
// .is-focused.input,
// .is-focused.textarea,
// .select select.is-active,
// .select select.is-focused,
// .select select:active,
// .select select:focus,
// .textarea:active,
// .textarea:focus {
//     // @include focus;
//     outline: 0;
// }

// Fa visible el focus a l'input[type="file"]
.file-label {
    border-radius: 0;
    &:focus-within:not(:focus-visible) {
        outline: 2px solid $black;
        outline-offset: 0.25rem;
    }
}

// Separació de checkboxes i radiobuttons
ul.field {
    > * + * {
        margin-top: var(--space-3xs);
    }
}

// Select
.select {
    width: 100%;
    select {
        width: 100%;
    }
    // &::after {
    //     border-color: $primary !important;
    //     border-width: 2px !important;
    //     width: 1rem !important;
    //     height: 1rem !important;
    //     margin-top: -0.8rem !important;
    //     right: 1.5rem !important;
    // }
}

// input file retocs
// .file-label {
//     &:hover {
//         .file-cta {
//             background-color: $file-cta-background-color;
//             color: $file-cta-color;
//         }
//     }
// }
// .file-cta {
//     font-size: var(--step--1);
//     border: 0;
//     padding-left: $control-height;
//     &::before {
//         content: "";
//         display: inline-flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         font-size: var(--step--1);
//         background: $primary
//             url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjcuNzE0IiBoZWlnaHQ9IjMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMC42NDMgMy4xNDNoNC40N2MuNTY4IDAgMS4xMTMuMjI2IDEuNTE0LjYyN2w5LjQ2IDkuNDZjLjQwMi40MDEuNjI3Ljk0Ni42MjcgMS41MTR2MTMuMDQyQTMuMjE0IDMuMjE0IDAgMCAxIDIzLjUgMzFIOS41NzFhMy4yMTQgMy4yMTQgMCAwIDEtMy4yMTQtMy4yMTR2LTguNTcyIi8+PHBhdGggZD0iTTE2IDMuNjc5djguMDM1YzAgMS4xODQuOTYgMi4xNDMgMi4xNDMgMi4xNDNoOC4wMzYiLz48cGF0aCBkPSJNNy40MjkgNC4yMTR2MTAuMTc5QTEuNTg2IDEuNTg2IDAgMCAxIDUuODIgMTZjLS44MDMgMC0xLjYwNy0uNjEtMS42MDctMS42MDdWNC43NUM0LjIxNCAyLjcwMiA1LjMyNCAxIDcuNDMgMWMyLjEwNCAwIDMuMjE0IDEuNjYgMy4yMTQgMy43MDhWMTRjMCAyLjg4LTEuODYzIDUuMjE0LTQuODIyIDUuMjE0QzIuODYzIDE5LjIxNCAxIDE2Ljg4IDEgMTRWOC41Ii8+PC9nPjwvc3ZnPg==")
//             no-repeat 50% 50%;
//         border-right: 3px solid #fff;
//         height: $control-height;
//         width: $control-height;
//         margin-left: calc($control-height * -1);
//         margin-right: 1em;
//     }
// }
// .file-name {
//     // background-color: transparent;
//     font-size: var(--step--2);
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     max-width: none;
// }

// Select amb addons
// .field.has-addons {
//     // p.control:first-child {
//     //     background-color: #e3e3e3;
//     //     border-color: #e3e3e3;
//     //     display: flex;
//     //     flex-direction: column;
//     //     justify-content: center;
//     //     align-items: center;
//     //     padding: var(--space-2xs);
//     // }
//     .select {
//         select {
//             padding-right: 3rem;
//         }
//         &::after {
//             border-color: #000 !important;
//             margin-left: 0.5rem;
//         }
//     }
//     .control:last-of-type.is-expanded {
//         input {
//             border-left: 0;
//         }
//     }
// }
// select.prefix {
//     background-color: $primary;
//     border-color: $primary;
// }


/*missatge rebut i error*/
/*https://bbbootstrap.com/snippets/animated-checkmark-50934051*/
.checkmark__circle {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	stroke: #7ac142;
	fill: none;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  .checkmark {
	width: 112px;
	height: 112px;
	border-radius: 50%;
	display: block;
	stroke-width: 5;
	stroke: #fff;
	stroke-miterlimit: 20;
	margin: 0 auto;
	box-shadow: inset 0px 0px 0px #7ac142;
	animation: fill 0.4s ease-in-out 0.4s forwards,
	  scale 0.3s ease-in-out 0.9s both;
  }
  .checkmark__check {
	transform-origin: 50% 50%;
	stroke-dasharray: 96;
	stroke-dashoffset: 96;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  @keyframes stroke {
	100% {
	  stroke-dashoffset: 0;
	}
  }
  @keyframes scale {
	0%,
	100% {
	  transform: none;
	}
	50% {
	  transform: scale3d(1.1, 1.1, 1);
	}
  }
  @keyframes fill {
	100% {
	  box-shadow: inset 0px 0px 0px 60px #7ac142;
	}
  }
  .missatge-form{
  border:solid 1px #000;
  border-radius: 36px;
  padding: 3em;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  background: #fff;
  }
  .missatge-form.success{
	  border-color:#7ac142;

  }
  .missatge-form.error{
	  border-color:#c82929;
  }

  .vh {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
.protecciodades{
  margin-top: var(--space-m);
}
legend{
  font-weight: bold;
  font-size: 1.2rem;
  color:#000;
}
.form-body > p{
  margin-bottom: var(--space-m);
}
ul.field{
  li{
    padding-left: 0;
    margin-bottom: var(--space-s);
    &::before{
      content: none;
    }
  }
}