.slider-nav-histories {
    .slick-arrow {
        position: static;
        width: 58px;
        height: 59px;
        transform: none;
        span {
            height: 100%;
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                display: block;
                background-position:
                    50%,
                    -58px 50%;
            }
        }
        .slick-prev-icon::before {
            background-image: url("img/ico-fletxa-esq.svg"), url("img/ico-fletxa-esq-2.svg");
        }
        .slick-next-icon::before {
            background-image: url("img/ico-fletxa-dre.svg"), url("img/ico-fletxa-dre-2.svg");
        }
        &:not(.slick-disabled) {
            &:hover span::before {
                background-position:
                    -58px 50%,
                    50%;
            }
        }
        &:focus {
            span {
                margin: 0;
            }
        }
    }
    .slick-prev.slick-disabled-custom {
        pointer-events: none;
        .slick-prev-icon {
            opacity: 0.25;
        }
    }
}
.slider--histories {
    .slick-list {
        overflow: visible;
        @include tablet {
            padding-left: 0 !important;
        }
    }
    .slick-track {
        display: flex;
    }
    .slick-slide {
        height: auto;
        > div {
            // padding: 0 $column-gap;
            padding: 0 1rem;
            height: 100%;
            > div {
                height: 100%;
                // position: relative;
                > div {
                    height: 100%;
                    // padding-top: 5rem !important;
                    // @include tablet {
                    //     padding-top: 23rem !important;
                    // }
                }
            }
        }
    }

    &__box {
        background-size: cover;
        background-repeat: no-repeat;
        a {
            text-decoration: none;
            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                top: 0;
                border-radius: $radius-large;
                background: rgba(0, 0, 0, 0.35);
                transition: background 0.125s $easing;
            }
            &:hover,
            &:focus {
                &::before {
                    background: rgba(0, 0, 0, 0.6);
                }
            }
        }
        .tag {
            position: absolute;
            left: var(--space-m);
            top: 0;
            transform: translateY(-50%);
        }
        blockquote {
            width: 65%;
            margin-left: auto;
            &::before {
                content: "";
                display: block;
                width: 50px;
                height: 50px;
                background-image: url("img/cometes.svg");
                background-repeat: no-repeat;
                position: absolute;
                left: -60px;
                top: -20px;
            }
            footer {
                &::before,
                &::after {
                    content: "\2013";
                    display: inline-block;
                }
                &::before {
                    margin-right: 3px;
                }
                &::after {
                    margin-left: 3px;
                }
            }
        }
        h3 {
            &::after {
                content: "\201D";
                display: inline-block;
            }
        }
        svg {
            position: absolute;
            right: 0;
            bottom: 0;
            border-bottom-right-radius: $radius-large;
            overflow: hidden;
        }
        p {
            position: absolute;
            /* z-index: 10; */
            bottom: 10px;
            right: 1rem;
            &::before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 1 1 8 0Zm0 4.121a.485.485 0 0 0-.485.485v3.152H4.364a.485.485 0 0 0 0 .97h3.151v3.15a.485.485 0 0 0 .97 0v-3.15h3.151a.485.485 0 1 0 0-.97H8.485V4.605A.485.485 0 0 0 8 4.121Z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
                // position: absolute;
                // bottom: 1rem;
                // right: 1rem;
            }
        }
    }
}

.medulla{
	.slider--histories__box svg path{
		fill:getColor(medulla);
	}
}

.plasma{
	.slider--histories__box svg path{
		fill: getColor(plasma);
	}
}
.teixits{
	.slider--histories__box svg path{
		fill: getColor(teixits);
	}
}
.sang{
	.slider--histories__box svg path{
		fill: $primary;
	}
}
.materna,.cordo{
	.slider--histories__box svg path{
		fill: getColor(materna);
	}
}