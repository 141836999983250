.gallery {
    gap: calc(var(--space-xs) / 2);
}
.img-gallery {
    gap: calc(var(--space-xs) / 2);
	flex-wrap: wrap;

	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;

}
.img-gallery li {
	flex: 1 1 300px; /* Mínim d'amplada de 200px i flexible per adaptar-se */
	max-width: 310px;
	display: block;
	position: relative;
  }

  .img-gallery li a {
	display: block;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  height: 250px; /* Alçada fixa per a totes les imatges */
  }

  .img-gallery li a img:hover {
	transform: scale(1.04);
  }

  .img-gallery img {
	width: 100%;
	height: auto;
	display: block;

	transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
