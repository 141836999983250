.slider-nav-ofertes {
    .slick-arrow {
        position: static;
        width: 58px;
        height: 59px;
        transform: none;
        span {
            height: 100%;
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                display: block;
                background-position:
                    50%,
                    -58px 50%;
            }
        }
        .slick-prev-icon::before {
            background-image: url("img/ico-fletxa-esq.svg"), url("img/ico-fletxa-esq-2.svg");
        }
        .slick-next-icon::before {
            background-image: url("img/ico-fletxa-dre.svg"), url("img/ico-fletxa-dre-2.svg");
        }
        &:not(.slick-disabled) {
            &:hover span::before {
                background-position:
                    -58px 50%,
                    50%;
            }
        }
        &:focus {
            span {
                margin: 0;
            }
        }
    }
    .slick-prev.slick-disabled-custom {
        pointer-events: none;
        .slick-prev-icon {
            opacity: 0.25;
        }
    }
}
.slider--ofertes {
    @include mobile {
        padding-left: var(--space-l);
    }
    .slick-list {
        overflow: visible;
        @include tablet {
            padding-left: 0 !important;
        }
    }
    .slick-track {
        display: flex;
    }
    .slick-slide {
        height: auto;
        > div {
            padding: 0 $column-gap * 4 0 0;
            height: 100%;
            > div {
                height: 100%;
                > div {
                    height: 100%;
                }
            }
        }
    }

    &__box {
        position: relative;
        // backdrop-filter en pseudoelement per evitar problema en Chrome (https://stackoverflow.com/questions/60997948/backdrop-filter-not-working-for-nested-elements-in-chrome)
        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba($grey-darker, 0.85);
            backdrop-filter: blur(7px);
            z-index: -1;
            border-radius: $radius-large;
        }
        > span {
            position: absolute;
            left: var(--space-s);
            transform: translateY(-50%);
        }
        // > div > div {
        //     &:first-of-type {
        //         border-top-left-radius: $radius-large;
        //         border-top-right-radius: $radius-large;
        //         overflow: hidden;
        //         img {
        //             object-fit: cover;
        //         }
        //     }
        //     &:last-of-type {
        //         border-bottom-left-radius: $radius-large;
        //         border-bottom-right-radius: $radius-large;
        //     }
        // }
        h3 {
            line-height: 1.3;
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: $radius-large;
                }
            }
        }
    }
}
