// https://fvsch.com/svg-icons
/**
 * Default icon style
 */
.icon {
    /* Use the current text color as the icon’s fill color. */
    fill: currentColor;
    /* Inherit the text’s size too. Also allows sizing the icon by changing its font-size. */
    // width: 1em;
    // height: 1em;
    width: 32px;
    height: 32px;
    /* The default vertical-align is `baseline`, which leaves a few pixels of space below the icon. Using `center` prevents this. For icons shown alongside text, you may want to use a more precise value, e.g. `vertical-align: -4px` or `vertical-align: -0.15em`. */
    vertical-align: middle;
    /* Paths and strokes that overflow the viewBox can show in IE11. */
    overflow: hidden;

    // icones outline
    /*
    &--outline-thin,
    &--outline-normal,
    &--outline-thick {
        fill: none;
        stroke: currentColor;
    }
    &--outline-thin {
        stroke-width: 16px;
    }
    &--outline-normal {
        stroke-width: 32px;
    }
    &--outline-thick {
        stroke-width: 24px;
    }
    */

    // mides icones
    &--small {
        width: 14px !important;
        height: 14px !important;
    }
    &--medium {
        width: 20px !important;
        height: 20px !important;
    }
    &--large {
        width: 64px !important;
        height: 64px !important;
    }

    // colors
    &--facebook {
        fill: #0773e6;
    }
    &--twitter {
        fill: #1b9bf0;
    }
    &--linkedin {
        fill: #0966c2;
    }
    &--instagram {
        fill: url(#ig-gradient);
    }
    &--telegram {
        fill: #279acc;
    }
    &--whatsapp {
        fill: #00dc2d;
    }
    &--email,
    &--link {
        fill: $grey-light;
    }
}


//animacio ok
/*https://bbbootstrap.com/snippets/animated-checkmark-50934051*/
.checkmark__circle {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	stroke: #7ac142;
	fill: none;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  .checkmark {
	width: 112px;
	height: 112px;
	border-radius: 50%;
	display: block;
	stroke-width: 5;
	stroke: #fff;
	stroke-miterlimit: 20;
	margin: 0 auto;
	box-shadow: inset 0px 0px 0px #7ac142;
	animation: fill 0.4s ease-in-out 0.4s forwards,
	  scale 0.3s ease-in-out 0.9s both;
  }
  .checkmark__circle.plasma {
	stroke: #fdc300;
 }
  .checkmark.plasma {
	box-shadow: inset 0px 0px 0px #fdc300;
	animation: fill-plasma 0.4s ease-in-out 0.4s forwards,
  }
  .checkmark__check {
	transform-origin: 50% 50%;
	stroke-dasharray: 96;
	stroke-dashoffset: 96;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  @keyframes stroke {
	100% {
	  stroke-dashoffset: 0;
	}
  }
  @keyframes scale {
	0%,
	100% {
	  transform: none;
	}
	50% {
	  transform: scale3d(1.1, 1.1, 1);
	}
  }
  @keyframes fill {
	100% {
	  box-shadow: inset 0px 0px 0px 60px #7ac142;
	}
  }
  @keyframes fill-plasma {
	100% {
	  box-shadow: inset 0px 0px 0px 60px #fdc300;
	}
  }