.lang {
    a {
        display: block;
        width: 37px;
        height: 36px;
        background-repeat: no-repeat;
        @media (hover: hover) {
            background-position:
                top left,
                -35px -35px;
            &:hover,
            &:focus {
                background-position:
                    -35px -35px,
                    top left;
            }
        }
        &[lang="es"] {
            background-image: url("img/ico-es-hover.svg");
            @media (hover: hover) {
                background-image: url("img/ico-es.svg"), url("img/ico-es-hover.svg");
            }
        }
        &[lang="ca"] {
            background-image: url("img/ico-cat-hover.svg");
            @media (hover: hover) {
                background-image: url("img/ico-cat.svg"), url("img/ico-cat-hover.svg");
            }
        }
    }
    // .a-privada & {
    //     border-top: 1px solid $primary;
    // }
}
