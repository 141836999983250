.scrolltop {
    // .js & {
    //     opacity: 0;
    // }
    // position: fixed;
    // bottom: var(--space-m);
    // right: var(--space-m);
    // transition: opacity 0.3s ease-in-out;
    // z-index: 2;
    // &.visible {
    //     opacity: 1;
    // }
    // &:hover {
    //     filter: invert(1);
    // }
    &::after {
        content: "";
        display: inline-block;
        width: 11px;
        height: 11px;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 0 11 11H0z' fill='%23000' fill-rule='evenodd'/%3E%3C/svg%3E");
        margin-left: 5px;
    }
}
