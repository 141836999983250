.btn-dubtes {
    border-radius: $radius-rounded;
    position: absolute;
    right: var(--space-2xl-3xl);
    bottom: var(--space-2xl-3xl);
    width: 3.125rem;
    height: 3.125rem;
    justify-content: center;
    align-items: center;
    text-decoration: none;
	display: none;
	@include tablet {
		display: flex;
	}
    span:last-child {
        max-width: 0;
        transition: max-width 0.125s $easing;
        overflow: hidden;
        white-space: nowrap;
    }
    &:hover,
    &:focus {
        width: auto;
        padding-left: calc(#{$button-padding-horizontal} + 0.25em);
        padding-right: calc(#{$button-padding-horizontal} + 0.25em);
        span:first-child {
            display: none;
        }
        span:last-child {
            display: inline-block;
            max-width: 100%;
            font-weight: bold;
            // font-family: "helvetica_neuebold", $family-primary;
            font-size: var(--step--2);
        }
    }
}
