.slider-nav-que-es {
    .slick-arrow {
        position: static;
        width: 58px;
        height: 59px;
        transform: none;
        span {
            height: 100%;
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                display: block;
                background-position:
                    50%,
                    -58px 50%;
            }
        }
        .slick-prev-icon::before {
            background-image: url("img/ico-fletxa-esq.svg"), url("img/ico-fletxa-esq-2.svg");
        }
        .slick-next-icon::before {
            background-image: url("img/ico-fletxa-dre.svg"), url("img/ico-fletxa-dre-2.svg");
        }
        &:not(.slick-disabled) {
            &:hover span::before {
                background-position:
                    -58px 50%,
                    50%;
            }
        }
        &:focus {
            span {
                margin: 0;
            }
        }
    }
	.slick-prev.slick-disabled-custom{
		pointer-events: none;
		.slick-prev-icon{
			opacity: .25;
		}
	}

}
.slider--que-es {
    .slick-list {
        @include tablet {
            padding-left: 0 !important;
        }
    }
    .slick-track {
        display: flex;
    }
    .slick-slide {
        height: auto;
        > div {
            padding: 0 $column-gap;
            height: 100%;
            > div {
                height: 100%;
                // position: relative;
                > div {
                    height: 100%;
					padding-top: 5rem !important;
					@include tablet {
                    	padding-top: 23rem !important;
					}
                }
            }
        }
    }

    &__box {
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 90%;
            border-bottom-left-radius: $radius-large;
            border-bottom-right-radius: $radius-large;
            background: transparent linear-gradient(to top, #5a1515d1, transparent);
            mix-blend-mode: multiply;
            transition: height 0.125s $easing;
        }
        h3 {
            z-index: 10;
            &::before {
                content: "";
                display: block;
                width: 26px;
                height: 26px;
                background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23FFF' cx='13' cy='13' r='13'/%3E%3Cg fill='%23AB192D'%3E%3Cpath d='M12 6h2v14h-2z'/%3E%3Cpath d='M20 12v2H6v-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                margin-bottom: var(--space-xs);
            }
            a {
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: $radius-large;
                }
            }
        }
        p {
            position: relative;
        }
        &:hover::before {
            height: 100%;
        }
    }
}
