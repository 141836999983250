// Modificacions per poder utilitzar classes .is-widescreen i .is-fullhd al .columns
// https://github.com/jgthms/bulma/pull/501#issuecomment-626680601
.columns {
    @include tablet {
        &:not(.is-desktop) {
          display: block;
        }
      }
    @include tablet {
        &:not(.is-desktop):not(.is-fullhd):not(.is-widescreen) {
            display: flex;
        }
    }
    @include desktop {
        &.is-desktop:not(.is-fullhd):not(.is-widescreen) {
            display: flex;
        }
    }
    @include widescreen {
        &.is-widescreen:not(.is-fullhd) {
            display: flex;
        }
    }
    @include fullhd {
        &.is-fullhd {
            display: flex;
        }
    }
}