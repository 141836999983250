// .help {
//     display: flex;
//     align-items: center;
//     font-weight: medium;
//     margin-top: var(--space-xs);
//     &::before {
//         content: "";
//         display: block;
//         margin-right: 0.5rem;
//     }
//     &.is-danger::before {
//         height: 22px;
//         width: 22px;
//         background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgc3Ryb2tlPSIjQTkwMDAxIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cGF0aCBkPSJNNiAxMy43OUM5LjA1NSAxMC41MyAxMS40NTYgOC4yNjcgMTMuMjAyIDciLz48cGF0aCBkPSJNMTMuMjAyIDEzLjc5QzEwLjE0NyAxMC41MyA3Ljc0NiA4LjI2NyA2IDciLz48L2c+PGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iMTAiLz48L2c+PC9zdmc+");
//     }
//     &.is-success::before {
//         height: 22px;
//         width: 22px;
//         background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgc3Ryb2tlPSIjMDBDMjI5IiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTQuNjY3IDkuMzM5YzEuODM1IDEuNjIgMy4wMzYgMi45OTMgMy42MDIgNC4xMTcgMy4wNTYtMy4yNTggNS40NTYtNS41MjEgNy4yMDMtNi43OSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iMTAiLz48L2c+PC9zdmc+");
//     }
// }

label.error {
    color: rgb(200, 41, 41);
    margin-top: 0.5em;
    display: inline-block;
    padding: 0.5em 1em 0.5em 3em;
    font-size: var(--step--2);
    border-radius: 4px;
    background: rgba(200, 41, 41, 0.1)
        url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCIgZmlsbD0iI0M4MjkyOSI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xMiAyQzYuNDggMiAyIDYuNDggMiAxMnM0LjQ4IDEwIDEwIDEwIDEwLTQuNDggMTAtMTBTMTcuNTIgMiAxMiAyem0xIDE1aC0ydi0yaDJ2MnptMC00aC0yVjdoMnY2eiIvPjwvc3ZnPg==")
        6px 50% no-repeat;
}
.protecciodades label.error {
    margin-top: 0 !important;
}
.input.error,
.is-checkradio.error,
select.error {
    border-color: rgb(200, 41, 41);
}
