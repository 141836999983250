.social {
    @include mobile {
        flex-direction: column;
    }
    ul {
        a {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}
