.info-escoles {
    @include desktop {
        &::before {
            content: "";
            display: block;
            height: 478px;
            width: 850px;
            background: url("img/escoles-foto-1.jpg") 100% 50% no-repeat;
            border-top-right-radius: $radius-rounded;
            border-bottom-right-radius: $radius-rounded;
            background-size: cover;
            position: absolute;
            left: -240px;
            top: var(--space-2xl);
        }
    }
    @include fullhd {
        &::before {
            left: 0;
        }
    }
}
