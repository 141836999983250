// .pagination-list {
//   > * + * {
//     margin-top: 0 !important;
//   }
//   li {
//     padding-left: 0 !important;
//     &:has(.pagination-link) + li {
//       .pagination-link {
//         border-left: 0;
//       }
//     }
//   }
//   a {
//     text-decoration: none;
//     &:hover,
//     &:focus {
//       border-color: transparent;
//       outline: 2px solid #000;
//       position: relative;
//       z-index: 1;
//     }
//   }
// }
// .pagination-previous,
// .pagination-next {
//   background-color: $grey-light;
//   svg {
//     stroke: #000;
//   }
//   &:hover,
//   &:focus {
//     background-color: $black;
//     border-color: $black;
//     svg {
//       stroke: #fff;
//     }
//   }
// }

// @include mobile {
//   .pagination {
//     flex-wrap: nowrap;
//     align-items: flex-start;
//   }
//   .pagination-previous {
//     order: 1;
//     flex-grow: 0;
//   }
//   .pagination-list {
//     order: 2;
//     li {
//       flex-grow: 0;
//     }
//   }
//   .pagination-next {
//     order: 3;
//     flex-grow: 0;
//   }
// }
.pagination {
  a {
    text-decoration: none;
  }
  .pagination-first {
    order: 1;
    margin-right: $pagination-item-margin;
	display: none;
	@include tablet {
		display: flex;
	}
  }
  .pagination-previous {
    order: 2;
  }
  .pagination-list {
    order: 3;
	display: none;
	@include tablet {
		display: flex;
	}
    // li {
    //   @include mobile {
    //     flex-grow: 0;
    //     flex-shrink: 0;
    //   }
    // }
  }
  .pagination-next {
    order: 4;
  }
  .pagination-last {
    order: 5;
    margin-left: $pagination-item-margin;
	display: none;
	@include tablet {
		display: flex;
	}
  }
  // .pagination-first,
  // .pagination-last,
  // .pagination-previous,
  // .pagination-next {
  //   flex-grow: 0;
  // }
  .pagination-previous,
  .pagination-next {
    &.is-disabled {
      &:hover {
        background-color: $pagination-disabled-background-color;
        color: $pagination-disabled-color;
      }
    }
  }
  .pagination-first,
  .pagination-last {
    &.is-disabled {
      background-color: $pagination-disabled-background-color;
      border-color: $pagination-disabled-border-color;
      box-shadow: none;
      color: $pagination-disabled-color;
      opacity: 0.5;
    }
  }
}
.pagination-link {
  border-radius: $radius-rounded;
  background-color: #fff;
  &:hover,
  &:focus {
    background-color: $grey-lighter;
  }
}
