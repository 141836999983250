[class*="gap"]:not(.is-gapless) {
    gap: var(--space-s);
}
// variants d'espai
.gap-1 {
    gap: var(--space-3xs) !important;
}
.gap-2 {
    gap: var(--space-2xs) !important;
}
.gap-3 {
    gap: var(--space-xs) !important;
}
.gap-4 {
    gap: var(--space-s) !important;
}
.gap-5 {
    gap: var(--space-m) !important;
}
.gap-6 {
    gap: var(--space-l) !important;
}
.gap-7 {
    gap: var(--space-xl) !important;
}
.gap-8 {
    gap: var(--space-2xl) !important;
}
.gap-9 {
    gap: var(--space-3xl) !important;
}
