.sub-nav {
    border-radius: $radius-large;
    [aria-current="page"],
    [aria-selected="true"] {
        font-weight: 700;
        // font-family: helvetica_neuebold, helvetica_neueregular, Helvetica, Arial, sans-serif;
    }
    a {
        color: $grey;
        line-height: 1;
        text-decoration: none;
        &:hover,
        &:focus,
        &[aria-current="page"],
        &[aria-selected="true"] {
            img,svg {
                filter: none;
                opacity: 1;
            }
        }
        &:hover {
            text-decoration: underline;
        }
    }
    svg {
        margin-right: var(--space-3xs);
    }
    img,svg {
        filter: brightness(0);
        opacity: 0.5;
    }
    li {
        a {
            &:hover,
            &:focus,
            &[aria-current="page"],
            &[aria-selected="true"] {
                color: $primary;
            }
        }
        a.sub-nav--sang {
            &:hover,
            &:focus,
            &[aria-current="page"],
            &[aria-selected="true"] {
                color: getColor(sang);
            }
        }
        a.sub-nav--plasma {
            &:hover,
            &:focus,
            &[aria-current="page"],
            &[aria-selected="true"] {
                color: getColor(plasma);
            }
        }
        a.sub-nav--materna {
            &:hover,
            &:focus,
            &[aria-current="page"],
            &[aria-selected="true"] {
                color: getColor(materna);
            }
        }
        a.sub-nav--medulla,a.sub-nav--medul-la {
            &:hover,
            &:focus,
            &[aria-current="page"],
            &[aria-selected="true"] {
                color: getColor(medulla);
            }
        }
        a.sub-nav--teixits {
            &:hover,
            &:focus,
            &[aria-current="page"],
            &[aria-selected="true"] {
                color: getColor(teixits);
            }
        }
    }
}


/*icons*/
.has-sub-nav{
	.main-header {
		position: fixed;
	}
	@include tablet {
		.main-content{
			padding-top: 10.89em!important;
		}
	}

}
/*.js #menu{
	display: none;
}*/