.autocomplete-suggestions {  border-top: 1px solid #d9d9d9; background: #FFF; cursor: default; overflow: auto; box-shadow: 0 2px 6px rgba(0,0,0,0.3);border-radius: 4px;margin-top:4px; }
.autocomplete-suggestion {background: url("/media/js/autocomplete/localitzacio.svg") 25px 50% no-repeat;background-size:16px;     color: #000;font-size: 16px;line-height:36px;padding: 7px 5px 5px 45px; white-space: nowrap; overflow: hidden;border-bottom: 1px solid #e6e6e6; }
.autocomplete-suggestion span {    font-size: 11px;
    color: #999; padding: 0 4px;}
.autocomplete-no-suggestion { padding: 2px 5px;}
.autocomplete-selected { background-color: #F0F0F0; }
.autocomplete-suggestions strong {

	color: #000;
}
