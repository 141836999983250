.has-filets-basics {
    @include tablet {
        z-index: -1;
        background-image: url("img/filets-basics-1.svg"), url("img/filets-basics-2.svg");
        background-repeat: no-repeat, no-repeat;
        background-position:
            -220px 170px,
            calc(100% - -200px) calc(100% - 40rem);
    }
}
