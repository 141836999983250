.slider-nav-escoles {
    .slick-arrow {
        position: static;
        width: 58px;
        height: 59px;
        transform: none;
        span {
            height: 100%;
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                display: block;
                background-position:
                    50%,
                    -58px 50%;
            }
        }
        .slick-prev-icon::before {
            background-image: url("img/ico-fletxa-esq.svg"), url("img/ico-fletxa-esq-2.svg");
        }
        .slick-next-icon::before {
            background-image: url("img/ico-fletxa-dre.svg"), url("img/ico-fletxa-dre-2.svg");
        }
        &:not(.slick-disabled) {
            &:hover span::before {
                background-position:
                    -58px 50%,
                    50%;
            }
        }
        &:focus {
            span {
                margin: 0;
            }
        }
    }
    .slick-prev.slick-disabled-custom {
        pointer-events: none;
        .slick-prev-icon {
            opacity: 0.25;
        }
    }
    @include tablet {
        justify-content: flex-end;
        margin-top: -5rem !important;
        position: relative;
        z-index: 1;
    }
    @include desktop {
        justify-content: flex-start;
        margin-left: calc(58.3333333333% + 1.25rem) !important;
    }
}
.slider--escoles {
    // .slick-list {
    //     overflow: visible;
    //     @include tablet {
    //         padding-left: 0 !important;
    //     }
    // }
    // .slick-track {
    //     display: flex;
    // }
    // .slick-slide {
    //     height: auto;
    //     > div {
    //         // padding: 0 $column-gap;
    //         padding: 0 1rem;
    //         height: 100%;
    //         > div {
    //             height: 100%;
    //             // position: relative;
    //             > div {
    //                 height: 100%;
    //                 // padding-top: 5rem !important;
    //                 // @include tablet {
    //                 //     padding-top: 23rem !important;
    //                 // }
    //             }
    //         }
    //     }
    // }
}
