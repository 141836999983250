// Caixes slider
.circular-slider__item {
    width: 17.375rem;
    > div:first-child {
        background-size: cover;
        height: 22.6875rem;
        &::after {
            content: "";
            display: block;
            width: 33px;
            height: 33px;
            background-image: url("data:image/svg+xml,%3Csvg width='33' height='33' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5 0C25.613 0 33 7.387 33 16.5S25.613 33 16.5 33 0 25.613 0 16.5 7.387 0 16.5 0Zm0 8.5a1 1 0 0 0-1 1V16H9a1 1 0 0 0 0 2h6.5v6.5a1 1 0 0 0 2 0V18H24a1 1 0 0 0 0-2l-6.5-.001V9.5a1 1 0 0 0-1-1Z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }
    }
    h3 {
        position: relative;
        top: -0.5em;
        left: var(--space-xs);
        line-height: 1;
        + p {
            margin-top: -1em;
        }
    }
    .tag {
        font-size: var(--step-0);
        font-weight: $weight-light !important;
        height: 1.5em;
        // font-family: helvetica_neuelight, $family-primary;
    }
    .bg {
        width: 100%;
        height: 5rem;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        border-bottom-left-radius: $radius-large;
        border-bottom-right-radius: $radius-large;
        padding-top: 5.5rem;
        transition: 0.125s $easing;
        transition-property: padding-top, height;
    }
    p {
        color: #fff;
        strong {
            color: $body-color;
        }
    }
    &--sang {
        .bg {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='278' height='249.875' viewBox='0 0 278 249.875'%3E%3Cpath d='M177.657-41.173c53.87-38.238,94.452-44.688,121.91-36.533V134.32a34.65,34.65,0,0,1-34.483,34.738H56.048A34.65,34.65,0,0,1,21.567,134.32V12.3c34.992,4.139,87.2-4.558,156.09-53.476' transform='translate(-21.567 80.817)' fill='%23ab192d'/%3E%3C/svg%3E");
        }
        p {
            strong {
                color: #fff;
            }
        }
        @include desktop {
            transform: rotate(-3deg);
        }
        > div:first-child {
            background: url("img/slider-circular-1.jpg");
        }
    }
    &--plasma {
        .bg {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='278' height='249.875' viewBox='0 0 278 249.875'%3E%3Cpath d='M177.657-41.173c53.87-38.238,94.452-44.688,121.91-36.533V134.32a34.65,34.65,0,0,1-34.483,34.738H56.048A34.65,34.65,0,0,1,21.567,134.32V12.3c34.992,4.139,87.2-4.558,156.09-53.476' transform='translate(-21.567 80.817)' fill='%23fdc300'/%3E%3C/svg%3E");
        }
        > div:first-child {
            background: url("img/slider-circular-2.jpg");
        }
    }
    &--medulla {
        .bg {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='278' height='249.875' viewBox='0 0 278 249.875'%3E%3Cpath d='M177.657-41.173c53.87-38.238,94.452-44.688,121.91-36.533V134.32a34.65,34.65,0,0,1-34.483,34.738H56.048A34.65,34.65,0,0,1,21.567,134.32V12.3c34.992,4.139,87.2-4.558,156.09-53.476' transform='translate(-21.567 80.817)' fill='%23ff8400'/%3E%3C/svg%3E");
        }
        > div:first-child {
            background: url("img/slider-circular-3.jpg");
        }
    }
    &--materna {
        .bg {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='278' height='249.875' viewBox='0 0 278 249.875'%3E%3Cpath d='M177.657-41.173c53.87-38.238,94.452-44.688,121.91-36.533V134.32a34.65,34.65,0,0,1-34.483,34.738H56.048A34.65,34.65,0,0,1,21.567,134.32V12.3c34.992,4.139,87.2-4.558,156.09-53.476' transform='translate(-21.567 80.817)' fill='%23cc28b0'/%3E%3C/svg%3E");
        }
        > div:first-child {
            background: url("img/slider-circular-4.jpg");
        }
    }
    &--teixits {
        .bg {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='278' height='249.875' viewBox='0 0 278 249.875'%3E%3Cpath d='M177.657-41.173c53.87-38.238,94.452-44.688,121.91-36.533V134.32a34.65,34.65,0,0,1-34.483,34.738H56.048A34.65,34.65,0,0,1,21.567,134.32V12.3c34.992,4.139,87.2-4.558,156.09-53.476' transform='translate(-21.567 80.817)' fill='%2376bd22'/%3E%3C/svg%3E");
        }
        > div:first-child {
            background: url("img/slider-circular-5.jpg");
        }
    }
    &:hover {
        > div::after {
            background-image: url("data:image/svg+xml,%3Csvg width='33' height='33' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5 0C25.613 0 33 7.387 33 16.5S25.613 33 16.5 33 0 25.613 0 16.5 7.387 0 16.5 0ZM24 16H9a1 1 0 0 0 0 2h15a1 1 0 0 0 0-2Z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
        }
        .bg {
            /*height: 15.625rem;*/
			height: 248px;//bug si fas més alt el svg, fixat per px
            padding-top: 7rem;
        }
    }
    .button {
        position: static;
        &::after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }
}

// Estils slider
.circular-slider-wrapper {
    @include tablet {
        margin-bottom: -103rem;
        height: 142rem;
    }
    .container {
        position: relative;
        max-width: 2274px;
        margin: auto;
        @media screen and (min-width: 2200px) {
            margin-left: 0;
            max-width: 100%;
        }
        &--btns {
            position: relative;
            z-index: 10;
            button {
                width: 58px;
                height: 59px;
                border: 0;
                padding: 0;
                cursor: pointer;
                background-color: transparent;
                background-repeat: no-repeat;
                background-position:
                    50%,
                    -58px 50%;
                &#prev {
                    background-image: url("img/ico-fletxa-esq.svg"), url("img/ico-fletxa-esq-2.svg");
                }
                &#next {
                    background-image: url("img/ico-fletxa-dre.svg"), url("img/ico-fletxa-dre-2.svg");
                }
                &:not([disabled]):hover {
                    background-position:
                        -58px 50%,
                        50%;
                }
            }
            button[disabled] {
                opacity: 0.25;
            }
        }
    }

    .wrapper-slider {
        position: relative;
        width: 2274px;
        height: 2274px;
        @media screen and (min-width: 2200px) {
            position: relative;
        }
    }

    .item {
        width: 17.375rem;
        z-index: 1;
    }

    svg {
        overflow: visible;
        height: 2274px;
        width: 2274px;
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .st0 {
        fill: none;
    }
}
.circular-slider-wrapper-2 {
    position: relative;
    // overflow-y: hidden;
    padding-top: 2rem;

    height: 36rem;
    top: 3em;
    overflow: hidden;

    // afegit
    @include desktop {
        top: -4rem;
        height: 52rem;
    }
    // /afegit

    // afegit
    .container {
        margin-left: 0;
        position: relative;
        @include desktop {
            margin-left: -250px;
        }

        .wrapper-slider {
            position: absolute;
            left: -960px;
            @include desktop {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    // /afegit
}
