.navigation {
    width: 100%;
    a {
        color: #fff;
        text-decoration: none;
        &:hover,
        &:focus {
            color: $primary;
        }
    }
}

// mòbil
.hamburger {
    cursor: default;
    width: 100%;
    text-align: left;
    @include mobile {
        .js-menu-open & {
            flex-direction: row-reverse;
        }
    }
    @include tablet {
        .a-privada & {
            &:hover {
                opacity: 1;
            }
        }
    }
    > span:last-of-type {
        // Oculta el text "Menú"
        @include mobile {
            @include visually-hidden;
        }
    }
    // Rotació icona menú
    @include tablet {
        svg {
            transition: transform 0.125s $easing;
        }
        &[aria-expanded="true"] {
            svg {
                transform: rotate(0.125turn);
            }
        }
    }
}

.js-menu-open #menu {
    position: absolute;
    right: 0;
    left: 0;
    top: 100%;
    z-index: 1;
    height: 100vh;
    padding-left: var(--space-l-xl);
    padding-right: var(--space-m);
	padding-bottom: var(--space-l);
    overflow-y: auto;

    // backdrop-filter filter en pseudoelement per evitar problema en Chrome (https://stackoverflow.com/questions/60997948/backdrop-filter-not-working-for-nested-elements-in-chrome)
    &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: inherit;
        background: rgba(0, 0, 0, 0.65);
        backdrop-filter: blur(26px);
        z-index: -1;
		min-height: 1025px;;
    }
}

// display: none perquè no es vegi el menú desplegat quan està carregant la pàgina
.js body:not(.js-menu-open) #menu {
    display: none;
}

// Adaptacions per Àrea privada (menú lateral sempre visible a desktop)
@include desktop {
    .js .a-privada {
        .hamburger {
            @include visually-hidden;
        }
        #menu {
            display: block !important;
            position: absolute;
            right: 0;
            left: 0;
            top: 100%;
            z-index: 1;
            height: 100vh;
            padding-left: var(--space-l-xl);
            padding-right: var(--space-m);
            overflow-y: visible;
            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: inherit;
                background: rgba(0, 0, 0, 0.65);
                backdrop-filter: blur(26px);
                z-index: -1;
            }
            p:first-child {
                position: relative;
                top: -2rem;
            }
        }
        .main-header__container:last-of-type::after {
            border-bottom-left-radius: 0;
        }
    }
}
