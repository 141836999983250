.slider--apd {

    .slick-list {
        overflow: visible;
        @include tablet {
            padding-left: 0 !important;
        }
    }
    .slick-track {
        display: flex;
    }
    .slick-slide {
        height: auto;
        > div {
            padding: 0 $column-gap * 4 0 0;
            height: 100%;

            > div {
                height: 100%;

                > div {
                    height: 100%;
                }
            }
        }
    }
}
.apd{
	#showPassword .on {
		display: none;
	}
	#showPassword{
		position: absolute;
		right: 10px;
	}
	#showPassword.show .off {
		display: none;
	}
	#showPassword.show .on {
		display: block;
	}
	.box-actions input{
		max-width: 100%;
	}
	.alertError {
		border: 1px solid #d0021b;
		margin-bottom: .2rem;
		padding: 1rem;
		font-size: 1.15rem;
		margin-top: 1.5rem;
		h1,h2,h3,h4{
			text-wrap: initial;
		}
		h2{
			font-size: var(--step-1) !important;
			    text-wrap: initial;
		}
		h3{
			font-size: var(--step-0) !important;
		}
		p,li{
			font-size: var(--step--1) !important;
		}
	}
	.alertMsg {
		border: 1px solid #65ad16;
		margin-bottom: .2rem;
		padding: 1rem;
		margin-top: 1.5rem;
		h1,h2,h3,h4{
			text-wrap: initial;
		}
		h2{
			font-size: var(--step-1) !important;
		}
		h3{
			font-size: var(--step-0) !important;
		}
		p,li{
			font-size: var(--step--1) !important;
		}
	}
	legend.label {
		font-weight: 700;
		color: $body-color;
  		font-size: $body-font-size;
	}

	.input-container__more {
	    display: none;
	    margin: 1rem 0 0;
	    padding: 1.5rem;
	    position: relative;
	    background-color: #f2f2f2;
	    width: 100%;
	}
	input[type=checkbox]:checked+label+.input-container__more {
		display: block;

	}
	.simple-list{
		flex-direction: column;
		@media screen and (min-width: 767px) {
			flex-direction: row;
		}
		li{
			position: relative;
			@media screen and (min-width: 767px) {
				&:not(:last-child)::after {
					content: "";
					position: absolute;
					right: -20px;
					/* border: solid 1px; */
					width: 1px;
					height: 100%;
					background: #ccc;
					top: 0;
				}
			}
		}
	}




		 .img{
			display: none;
		}
		.box-actions{

			padding: 1em;

		}
		@media screen and (min-width: 767px) {
			.box-actions{

				padding: 0!important;

			}
			.form{

				padding: var(--space-l) !important;
			}
		}

		@media screen and (min-width: 1024px) {

			.form{
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				//justify-content: center;


			}
			/*.form-wrapper{
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: center;

			}*/
			.apd-form{
				width: 65%;
			}
			.img{
				width: auto;
				display: block;
				height: 100%;
				left: 0;
				top: 0;
				border-top-left-radius: 12px;
				border-bottom-left-radius: 12px;
			}
		}
}

// editar dades

  dl.dades {
	margin-bottom:  var(--space-l);
	dt {
		font-weight: bold;
	  margin-bottom:  var(--space-xs);
	  text-transform: uppercase;
	}
	dd {

	  margin-left: 0;
	  padding-bottom:  var(--space-xs);
	  margin-bottom:  var(--space-xs);
	  border-bottom: 1px solid #d7d7d7;
	  position: relative;
	  &:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
		border-bottom: 0;
	  }
	  #showPassword{
		right: 10px;
		top: 6px;
	  }
	}
  }
  .apd-edit {
	label {
	  margin-bottom:  var(--space-2xs);
	  display: block;
	}
	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="number"],
	select {
	  border-radius: 6px;
	  border: solid 1px #979797;
	  background-color: #f7f7f7;
	  padding:  var(--space-xs);
	  width: 100%;
	}
	.buttons-container {
	  display: flex;
	  justify-content: space-between;
	  margin-bottom:  var(--space-l);
	  align-items: center;
	  gap: var(--space-l);
	}
	.button {
	  flex-grow: 1;
	  &:first-child {
		margin-left: 0;
	  }
	  &:last-child {
		margin-right: 0;
	  }
	}
	.inputs-wrapper {
	  @media screen and (min-width: 30em) {
		display: flex;
		flex-wrap: wrap;
	  }
	}
	.input-container {
	  border: 0;
	  @media screen and (min-width: 30em) {
		width: 47%;
		width: calc(50% - 1.5rem);
	  }
	}
  }
