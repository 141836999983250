.info-recerca {
    @include desktop {
        &::before {
            content: "";
            display: block;
            height: 532px;
            width: 720px;
            background: url("img/imatge-investigacio-en-xifres.jpg") no-repeat;
            border-top-right-radius: $radius-rounded;
            border-bottom-right-radius: $radius-rounded;
            background-size: cover;
            position: absolute;
            top: 50%;
            transform: translate(-20%, -50%);
        }
    }
    @include widescreen {
        &::before {
            transform: translate(-15%, -50%);
        }
    }
	@media screen and (min-width: 1600px) {
		&::before {
            transform: translate(-5%, -50%);
        }
	}
}
