.fixed-button {
    position: fixed;
    bottom: 2rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    @include touch {
        bottom: 5rem;
    }
    .button {
        white-space: normal;
    }
}
