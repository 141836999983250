.xlarge-text-block {
    @include mobile {
        > .columns {
            display: flex;
            flex-direction: column-reverse !important;
        }
    }
    .tag {
        position: absolute;
        left: 4rem;
        top: -0.5em;
    }
    > span {
        // display: none;
        // @include desktop {
        display: block;
        opacity: 0.17;
        position: absolute;
        line-height: 1;
        z-index: -1;
        // }
        @include mobile {
            bottom: -5rem;
            left: 0;
        }
        @include tablet {
            bottom: auto;
            left: auto;
        }
    }
    @include tablet {
        &:nth-child(even) {
            .column:first-child {
                text-align: right;
            }
        }
    }
    &:nth-child(1) {
        @include desktop {
            .column:last-child {
                margin-top: var(--space-2xl);
            }
        }
        > span {
            // font-size: 371px;
            // 195px -> 371px
            font-size: clamp(12.1875rem, 8.3614rem + 19.1304vi, 23.1875rem);
            @include tablet {
                top: 8rem;
                right: 5rem;
            }
        }
    }
    &:nth-child(2) {
        @include desktop {
            h3 {
                max-width: 20ch;
                margin-left: auto;
            }
            .column:last-child {
                margin-top: var(--space-xl);
            }
        }
        > span {
            // font-size: 268px;
            // 195px -> 268px
            font-size: clamp(12.1875rem, 10.6005rem + 7.9348vi, 16.75rem);
            @include tablet {
                top: 12rem;
                left: 7rem;
            }
        }
    }
    &:nth-child(3) {
        @include desktop {
            h3 {
                max-width: 20ch;
            }
            .column:last-child {
                margin-top: var(--space-xl);
            }
        }
        > span {
            // font-size: 351px;
            // 195px -> 351px
            font-size: clamp(12.1875rem, 8.7962rem + 16.9565vi, 21.9375rem);
            @include tablet {
                top: 8rem;
                right: -2rem;
            }
        }
    }
    &:nth-child(4) {
        @include desktop {
            h3 {
                max-width: 20ch;
                margin-left: auto;
            }
            .column:last-child {
                margin-top: var(--space-xl);
            }
        }
        > span {
            // font-size: 343px;
            // 195px -> 343px
            font-size: clamp(12.1875rem, 8.9701rem + 16.087vi, 21.4375rem);
            @include tablet {
                top: 9rem;
                left: -2rem;
            }
        }
    }
    &:nth-child(5) {
        @include desktop {
            h3 {
                max-width: 20ch;
            }
            .column:last-child {
                margin-top: var(--space-2xl);
            }
        }
        > span {
            // font-size: 472px;
            // 195px -> 472px
            font-size: clamp(12.1875rem, 6.1658rem + 30.1087vi, 29.5rem);
            @include tablet {
                top: 6rem;
                right: 0;
            }
        }
    }
    &.xlarge-text-block--centered {
        > span {
            // font-size: 287px;
            // 170px -> 287px
            font-size: clamp(10.625rem, 8.0815rem + 12.7174vi, 17.9375rem);
            top: 50%;
            left: 50%;
            right: auto;
            transform: translate(-50%, -50%);
        }
    }
    // Missió / Visió (Recerca)
    &.xlarge-text-block--mv {
        p {
            @include desktop {
                max-width: 45ch;
                text-wrap: balance;
            }
        }
        &:nth-child(1) {
            > span {
                // font-size: 273px;
                // 168px -> 273px;
                font-size: clamp(10.5rem, 8.2174rem + 11.413vi, 17.0625rem);
                top: 12rem;
                right: 0;
                @include mobile {
                    top: 27rem;
                    left: -4rem;
                }
            }
        }
        &:nth-child(2) {
            > span {
                // font-size: 268px;
                // 168px -> 268px;
                font-size: clamp(10.5rem, 8.3261rem + 10.8696vi, 16.75rem);
                top: 12rem;
                left: 6rem;
                @include mobile {
                    top: 27rem;
                    left: -2rem;
                }
            }
            p {
                @include desktop {
                    margin-left: auto;
                }
            }
        }
    }
}
