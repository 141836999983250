html {
    scroll-behavior: smooth;
}
body {
    position: relative; /* per posicionar el background a la home */
    // overflow-x: hidden; /* per a l'slider circular */
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    // font-family: helvetica_neuebold, $family-primary;
    // em {
    //     font-family: helvetica_neuebold_italic, $family-primary;
    // }
}
h1 {
    font-size: var(--step-5);
}
h2 {
    font-size: var(--step-4);
    --flow-space: var(--space-l);
}
h3 {
    font-size: var(--step-3);
    --flow-space: var(--space-m);
}
h4 {
    font-size: var(--step-2);
}
h5 {
    font-size: var(--step-1);
}
h6 {
    font-size: var(--step-0);
}
h1,
h2,
button,
input,
label {
    line-height: 1;
}
h3,
h4 {
    line-height: 1.1;
}
h1,
h2,
h3,
h4 {
    text-wrap: balance;
}

ul {
    list-style: initial;
}
ul,
ol {
    // Safari + VoiceOver
    // Llistes preserven semàntica quan són filles d'un element 'nav', per tant no necessiten role="list" i poden tenir 'list-style: none;'
    // https://twitter.com/SaraSoueidan/status/1609223633544552449
    nav &,
    &[role="list"] {
        list-style: none;
    }
}

// Estils de llistes (https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html)
ul:not([role]),
ol:not([role]) {
    padding-left: 0;
    > * + * {
        margin-top: var(--space-2xs);
    }
}

ul:not([role]) {
    list-style-position: inside;
    // Ocultem el ::marker
    ::marker {
        content: "";
        font-size: 0; /* Safari support is limited to color and font-size. See bug https://bugs.webkit.org/show_bug.cgi?id=204163 */
    }
    > li {
        padding-left: 2rem;
        position: relative;
        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0.75em;
            background: $primary;
            width: 1.25em;
            height: 1px;
        }
    }
    ul {
        margin-top: var(--space-xs);
        margin-left: var(--space-xs);
        font-size: var(--step--1);
        // > * + * {
        //     margin-top: var(--space-2xs);
        // }
        li {
            padding-left: 1.5rem;
            &::before {
                content: "";
                top: 0.5em;
                width: 8px;
                height: 8px;
                border: 2px solid $primary;
                background-color: transparent;
                border-radius: 50%;
            }
        }
        ul {
            font-size: var(--step--2);
            margin-left: var(--space-s);
            li {
                padding-left: 1.5rem;
                &::before {
                    top: 0.4em;
                    width: 7px;
                    height: 7px;
                    background-color: $primary;
                }
            }
        }
    }
}

ol:not([role]) {
    list-style: decimal;
    list-style-position: outside;
    margin-left: 1.25rem;
    li {
        padding-left: var(--space-3xs);
        &::marker {
            font-weight: 500;
            color: $primary;
        }
    }
}

a {
    text-decoration: underline 0.085em;
    text-underline-offset: 0.3em;
    word-break: break-word;
    &:hover {
        text-decoration: none;
    }
}

// strong {
//     font-family: helvetica_neuebold, $family-primary;
// }
// em {
//     font-family: helvetica_neueitalic, $family-primary;
// }

// strong em,
// em strong {
//     font-family: helvetica_neuebold_italic, $family-primary;
// }

address {
    font-style: normal;
}

abbr {
    text-decoration: none;
}

// :focus {
//     @include focus;
// }

details[open] {
    summary {
        border-top-left-radius: $radius-large;
        border-top-right-radius: $radius-large;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        span span::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22'%3E%3Ccircle cx='11' cy='11' r='11' fill='%23ab192d'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2' d='M16 11.333H6'/%3E%3C/svg%3E");
        }
        + div {
            border-bottom-left-radius: $radius-large;
            border-bottom-right-radius: $radius-large;
        }
    }
    .column {
        box-sizing: border-box; /* hack provisional per un problema d'herència: https://stackoverflow.com/questions/75347448/box-sizing-not-inherited-through-details-element */
    }
	details{
		summary {
        	border-bottom-left-radius: $radius-large;
        	border-bottom-right-radius: $radius-large;
		}
	}
	details[open] {
		summary {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		details{
			summary {
				border-bottom-left-radius: $radius-large;
				border-bottom-right-radius: $radius-large;
			}
		}
		details[open] {
			summary {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
}
details[open] {
    summary span.summary-icon::after {
        transform: rotate(0.125turn);
    }
}
summary {
    list-style: none;
    span.summary-icon::after {
        content: "";
        display: block;
        width: 22px;
        height: 22px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22'%3E%3Ccircle cx='11' cy='11' r='11' fill='%23ab192d'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2' d='M16 11.333H6'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2' d='M11 6.333v10'/%3E%3C/svg%3E");
    }
    &::-webkit-details-marker {
        display: none;
    }
}

table {
    @extend .table;
    border-collapse: collapse;
    font-size: var(--step--1);
    width: 100%;
    th {
        // font-family: $family-secondary;
        // font-weight: normal;
        // font-size: var(--step-1);
        line-height: 1.2;
    }
    thead {
        th {
            vertical-align: bottom;
            // border: 0;
        }
    }
    tbody {
        // th {
        //     padding-top: var(--space-2xs);
        // }
        // th,
        // td {
        //     border-top: 2px solid $primary;
        // }
        td > * + * {
            margin-top: var(--space-2xs);
        }
    }
}

figure {
    img {
        display: inline-block;
    }
}
figcaption {
    font-size: var(--step--1);
    padding: var(--space-2xs) 0 var(--space-2xs) var(--space-xs);
    margin-top: var(--space-2xs);
    border-left: 1px solid $primary;
}

// img {
//     height: auto;
// }

// fieldset {
//     border: 0;
//     padding: 0;
//     margin-left: 0;
//     margin-right: 0;
// }
// legend {
//     font-size: var(--step--1);
//     padding: 0;
//     width: 100%;
//     // margin-bottom: var(--space-s);
//     text-transform: uppercase;
// }
// ::placeholder {
//     color: #000 !important;
// }

// input,
// button,
// textarea,
// select {
//     font: inherit;
// }

// iframe {
//     border: 0;
// }

// dl {
//     dt {
//         text-transform: uppercase;
//         font-size: var(--step--2);
//     }
//     dd {
//         font-weight: bold;
//         font-size: var(--step--1);
//     }
// }

:target {
    scroll-margin-block: 5ex;
}

// // A11y toggle (http://edenspiekermann.github.io/a11y-toggle/)
[aria-hidden="true"],
[data-a11y-toggle]:not([aria-controls]) {
    display: none;
}

// Excepcions (https://github.com/edenspiekermann/a11y-toggle/commit/74d9d7ff3fe98dd78f8ae96f08595691ea52c329)
/**
 * 1. Globally hiding elements with [aria-hidden="true"] can have rendering
 *    issues with third party integrations. Only known to date is with Google
 *    Maps, in which it prevents the map tiles to render. Therefore it needs to
 *    be resetted inside a Google Maps container.
 *    See: https://github.com/edenspiekermann/a11y-toggle/issues/30
 * 2. Fallback value in case the `initial` value is not supported.
 *    See: http://caniuse.com/#feat=css-initial-value
 */
.gm-style [aria-hidden="true"],
.scrolltop [aria-hidden="true"],
.slider [aria-hidden="true"],
.slider--home-mobile__nav [aria-hidden="true"],
.icon[aria-hidden="true"],
.mfp-close [aria-hidden="true"] {
    /* 1 */
    display: block; /* 2 */
    display: initial;
}

a.is-not-underlined{
	text-decoration: none!important;
}
.has-background-plasma{
	background-color:getColor(plasma);
}
.has-border-plasma{
	border: solid 1px getColor(plasma);
}