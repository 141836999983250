.blurred-container {

	@media screen and (min-width: 600px) {
		margin-left: var(--space-l-xl);
		margin-right: var(--space-l-xl);
	}
    // backdrop-filter en pseudoelement per evitar problema en Chrome (https://stackoverflow.com/questions/60997948/backdrop-filter-not-working-for-nested-elements-in-chrome)
    &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(208, 206, 206, 0.7);
        backdrop-filter: blur(8px);
        z-index: -1;
        border-radius: var(--fluid-36-154);
    }
    &--white {
        &::before {
            background: rgba(255, 255, 255, 0.7);
        }
    }
    &--primary {
        &::before {
            background: rgba(171, 25, 45, 0.32);
        }
    }
    &--type-1 {
        margin-left: 0;
        margin-right: 0;
        &::before {
            border-radius: 36px 36px 0 0;
        }
    }
    &--type-2 {
        margin-left: 0;
        margin-right: 0;
        &::before {
            border-radius: 0;
        }
    }
    &--medium {
        &::before {
            border-radius: var(--fluid-36-80);
        }
    }
}
