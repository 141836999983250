// ------
// GLOBAL
// ------
// @import "base/helvetica-neue";
// @import "base/googleFonts_Open Sans";
@import "base/googleFonts_Roboto";
@import "base/utopia";

// Bulma cusotmize
// ---------------
@import "bulma-customize/all";

// Variables
// ---------
@import "variables/hamburger";
// @import "variables/mfp";
@import "variables/slick-slider";
@import "variables/vars";

// Libraries
// ---------
@import "lib/lib";

// Mixins
// -------
@import "helpers/mixins";
@import "helpers/functions";

// Base styles
// -----------
@import "base/base";

// --------------
// Inici CUBE CSS (https://cube.fyi/)
// --------------

// Composition (https://cube.fyi/composition.html#composition)
// -----------
@import "composition/area-privada-layout";
@import "composition/columns";
@import "composition/sidebar";
// @import "composition/grid";

// Utility (https://cube.fyi/utility.html#utility)
// -------
@import "utilities/flow";
@import "utilities/full-link";
@import "utilities/gap";
// @import "utilities/gradient";
@import "utilities/h-100";
@import "utilities/hidden-svg";
@import "utilities/hyphens";
@import "utilities/is-bordered";
@import "utilities/is-relative";
@import "utilities/link-decoration-none";
@import "utilities/radius";
@import "utilities/rounded";
@import "utilities/simple-list";
@import "utilities/static";
@import "utilities/table-responsive";
@import "utilities/text-columns";
@import "utilities/text-weight-black";
@import "utilities/tt-none";
@import "utilities/upp";
@import "utilities/w-100";
@import "utilities/wrapper";

// Block (https://cube.fyi/block.html#block)
// -----
@import "blocks/borders-list";
@import "blocks/boxes-list";
@import "blocks/breadcrumb";
@import "blocks/button";
@import "blocks/checkradio";
@import "blocks/collapsible";
@import "blocks/cols-list";
@import "blocks/dest-list";
@import "blocks/download-doc";
@import "blocks/dropdown-menu";
@import "blocks/form";
// @import "blocks/form-contact";
@import "blocks/form-help";
// @import "blocks/form-label";
// @import "blocks/form-login";
@import "blocks/full-bleed";
@import "blocks/gallery";
@import "blocks/highlighted-link";
@import "blocks/highlighted-p-1";
@import "blocks/highlighted-p-2";
@import "blocks/highlighted-p-3";
@import "blocks/icon";
@import "blocks/icon-large";
@import "blocks/ihavecookies";
@import "blocks/main-nav";
@import "blocks/mfp";
@import "blocks/navigation";
@import "blocks/pagination";
@import "blocks/related-docs";
@import "blocks/scrolltop";
@import "blocks/share-list";
@import "blocks/site-logo";
@import "blocks/skip";
@import "blocks/slider";
@import "blocks/tabs";
@import "blocks/text-base";
@import "blocks/toggle";
@import "blocks/video-container";

// Blocks específics del projecte
// ------------------------------
@import "blocks/area-privada";
@import "blocks/ap-icones";
@import "blocks/blurred-container";
// @import "blocks/box";
@import "blocks/boxes-grid";
@import "blocks/boxes-grid-variable";
@import "blocks/btn-dubtes";
@import "blocks/circular-slider";
@import "blocks/cites-seguents";
// @import "blocks/colabs-list";
@import "blocks/contact";
// @import "blocks/destacat-carpeta";
@import "blocks/desplegable-destacat";
@import "blocks/desplegable-destacat-b";
@import "blocks/desplegable-foto";
@import "blocks/donacions-sang";
@import "blocks/donacions-list";
@import "blocks/filets-basics";
@import "blocks/filets-bg";
@import "blocks/fixed-btn";
@import "blocks/footer";
@import "blocks/foto-sang-2";
@import "blocks/grafic-grups-recerca";
@import "blocks/header";
@import "blocks/highlight-title";
@import "blocks/icon-box";
@import "blocks/icon-circle";
// @import "blocks/icons-container";
// @import "blocks/info-block";
@import "blocks/info-dest";
@import "blocks/info-donacions";
@import "blocks/info-escoles";
@import "blocks/info-productes-i-serveis";
@import "blocks/info-recerca";
@import "blocks/info-title";
@import "blocks/info-treballa";
@import "blocks/lang";
@import "blocks/link-dest";
// @import "blocks/login";
@import "blocks/main-content";
@import "blocks/motius-list";
@import "blocks/newsletter-form";
@import "blocks/offset-title";
@import "blocks/pills-list";
@import "blocks/pills-nav";
@import "blocks/rounded-img";
@import "blocks/rounded-info";
@import "blocks/search-form";
@import "blocks/slider-escoles";
@import "blocks/slider-histories";
@import "blocks/slider-home";
@import "blocks/slider-memories";
@import "blocks/slider-news";
@import "blocks/slider-ofertes";
@import "blocks/slider-projectes";
@import "blocks/slider-que-es";
@import "blocks/slider-recursos";
@import "blocks/slider-testimonis";
@import "blocks/secondary-nav";
@import "blocks/social";
@import "blocks/sub-nav";
@import "blocks/table";
@import "blocks/tag";
@import "blocks/top-img";
@import "blocks/video-link";
@import "blocks/xifres-list";
@import "blocks/xlarge-text-block";
@import "blocks/gallery";
@import "blocks/apd";//area privada

@import "blocks/consell-i-comite";

@import "../../node_modules/magnific-popup/dist/magnific-popup.css";

@import "blocks/inscdonacio";//web 2017,revisar si  es pot eliminar quan les reserves s'integrin
@import "blocks/autocomplete";//web 2017, revisar si es pot eliminar quan les reserves s'integrin


@import "utilities/paddings-margins-mobiles";//personalitatzats per margins i paddings per mobils, tablet....
@import "blocks/materna";

// només utilitzar pels exemples:
.box {
    color: #fff;
    height: 100%;
    background-color: #666;
    // padding: 1rem;
}
.test::before {
    content: "";
    display: inline-block;
    width: 1em; /*Modificar segons la necessitat. Ha de ser igual que el height.*/
    height: 1em; /*Modificar segons la necessitat. Ha de ser igual que el width.*/
    background-image: url("../img/sprites-svg/skull.svg");
    margin-right: var(--step-1);
}
.test--base64::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tIEZvbnQgQXdlc29tZSBGcmVlIDUuMTUuNCBieSBAZm9udGF3ZXNvbWUgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbSBMaWNlbnNlIC0gaHR0cHM6Ly9mb250YXdlc29tZS5jb20vbGljZW5zZS9mcmVlIChJY29uczogQ0MgQlkgNC4wLCBGb250czogU0lMIE9GTCAxLjEsIENvZGU6IE1JVCBMaWNlbnNlKSAtLT48cGF0aCBkPSJNMjU2IDBDMTE0LjYgMCAwIDEwMC4zIDAgMjI0YzAgNzAuMSAzNi45IDEzMi42IDk0LjUgMTczLjcgOS42IDYuOSAxNS4yIDE4LjEgMTMuNSAyOS45bC05LjQgNjYuMmMtMS40IDkuNiA2IDE4LjIgMTUuNyAxOC4ySDE5MnYtNTZjMC00LjQgMy42LTggOC04aDE2YzQuNCAwIDggMy42IDggOHY1Nmg2NHYtNTZjMC00LjQgMy42LTggOC04aDE2YzQuNCAwIDggMy42IDggOHY1Nmg3Ny43YzkuNyAwIDE3LjEtOC42IDE1LjctMTguMmwtOS40LTY2LjJjLTEuNy0xMS43IDMuOC0yMyAxMy41LTI5LjlDNDc1LjEgMzU2LjYgNTEyIDI5NC4xIDUxMiAyMjQgNTEyIDEwMC4zIDM5Ny40IDAgMjU2IDB6bS05NiAzMjBjLTM1LjMgMC02NC0yOC43LTY0LTY0czI4LjctNjQgNjQtNjQgNjQgMjguNyA2NCA2NC0yOC43IDY0LTY0IDY0em0xOTIgMGMtMzUuMyAwLTY0LTI4LjctNjQtNjRzMjguNy02NCA2NC02NCA2NCAyOC43IDY0IDY0LTI4LjcgNjQtNjQgNjR6Ii8+PC9zdmc+");
}
.test--color::before {
    background-image: url("../img/sprites-svg/skull-fuchsia.svg");
}
