.top-img {
    width: 100%;
    max-height: 39rem;
    object-fit: cover;
	/*@media screen and (min-width: 2400px) {
		max-height: 45rem;
	}*/
    @include mobile {
        height: 20rem;
    }
    + .wrapper {
        position: absolute;
        left: 50%;
        top: 2rem;
        transform: translateX(-50%);
        width: 100%;
		@include tablet {
			top: 5rem;
		}
    }
}

.opacity-top-img{
	background-image: linear-gradient(to right,rgba(255, 255, 255, 0.62), rgba(255, 255, 255, 0));
	position:absolute;
	height:100%;
	width:100%;
}