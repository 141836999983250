.filets {
    position: absolute;
    z-index: -1;
    width: 100%;
    svg {
        position: absolute;
    }
    @include touch {
        display: none;
    }
}
// Home
.filets--home {
    top: -9px;
    bottom: 0;
    max-width: 1480px;
    left: 50%;
    transform: translateX(-50%);

    .svg-bg--1 {
        margin-top: 0;
        margin-left: -40px !important;
    }
    .svg-bg--2 {
        margin-left: -20px !important;
    }
    .svg-bg--3 {
        margin-top: -40px;
        margin-left: -23px !important;
    }
    @media screen and (min-width: 1600px) {
        .svg-bg--1 {
            margin-top: 0;
            margin-left: -6px !important;
        }
        .svg-bg--2 {
            margin-left: 20px !important;
        }
        .svg-bg--3 {
            margin-top: -40px;
            margin-left: 12px !important;
        }
    }
    .path {
        fill: none;
        stroke-width: 6px;
        visibility: hidden;
        &--1 {
            stroke: url(#linear-gradient-1);
        }
        &--2 {
            stroke: url(#linear-gradient-2);
        }
        &--3 {
            stroke: url(#linear-gradient-3);
        }
    }
}

// Donacions
.filets--donacions {
    top: -160px;
    bottom: 0;
    max-width: 1480px;
    left: 50%;
    transform: translateX(-50%);
}

// Donacions Sang
.filets--donacions-sang {
    top: -560px;
    bottom: 0;
    max-width: 1480px;
    left: 50%;
    transform: translateX(-44%);
    overflow-y: hidden;
}

// Donacions Sang
.filets--donacions-medulla {
    top: -80px;
    bottom: 0;
    max-width: 1480px;
    left: 50%;
	transform: translateX(-34%);
    overflow-y: hidden;
}
// Donacions Plasma interiors
.filets--donacions-plasma {
    top: -100px;
    bottom: 0;
    max-width: 1480px;
    left: 50%;
    transform: translateX(-44%);
    overflow-y: hidden;
}

// Productes i serveis
.filets--productes-i-serveis {
    top: -261px;
    max-width: 1440px;
    left: 50%;
    transform: translateX(-55%);
}

// Productes i serveis Sang
.filets--productes-i-serveis-sang {
    top: -194px;
    max-width: 1440px;
    left: 50%;
    transform: translateX(-50%);
    // overflow-y: hidden;
}
.filets--productes-i-serveis-llet {
    top: -594px;
    max-width: 1440px;
    left: 50%;
    transform: translateX(-50%);
    // overflow-y: hidden;
}
// Recerca
.filets--recerca {
    top: -98px;
    max-width: 1440px;
    left: 50%;
    transform: translateX(-58%);
    // overflow-y: hidden;
	@include widescreen {
		top: -278px;
	}
}

// Escoles
.filets--comunitat-escoles {
    top: -53px;
    max-width: 1440px;
    left: 50%;
    transform: translateX(-58%);
    // overflow-y: hidden;
}

// Històries
.filets--histories {
    top: -178px;
    max-width: 1440px;
    left: 50%;
    transform: translateX(-46%);
    // overflow-y: hidden;
}

// Treballa amb nosaltres
.filets--treballa {
    // top: -116px;
    top: -95px;
    max-width: 1440px;
    left: 50%;
    transform: translateX(-50%);
    // overflow-y: hidden;
}
