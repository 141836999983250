.donacions-sang {
    li div div {
        width: min-content;
    }
    span {
        line-height: 1;
    }
    &__animacio {
        opacity: 0;
    }
    svg:hover {
        .donacions-sang__animacio {
            opacity: 1;
        }
        .donacions-sang__sang {
            opacity: 0;
        }
    }
}
