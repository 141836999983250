.xifres-list {
    @include tablet {
        li:nth-child(2) {
            margin-top: 13rem;
        }
        li:nth-child(3) {
            margin-top: 27rem;
        }
        li:nth-child(4) {
            margin-top: 50rem;
        }
    }
}
