.cites-seguents {
    @include mobile {
        flex-direction: column;
        > div {
            &::after {
                position: static !important;
                height: 1px !important;
                width: 100% !important;
                margin-top: var(--space-m);
            }
        }
    }
    @include touch {
        border-radius: $radius-large;
    }
    > div {
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            right: calc(var(--space-m) / 2 * -1);
            top: 0;
            width: 1px;
            height: 100%;
            background-color: $primary;
        }
        @include tablet {
            &:nth-last-child(2)::after {
                background-color: $grey;
            }
        }
        &:last-child::after {
            display: none;
        }
    }
}
