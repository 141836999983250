.contact {
    &__item {
        @include tablet {
            display: block !important;
        }
        img {
            flex: 0 0 2rem;
        }
    }
    span {
        flex: 0 0 6rem;
        @include tablet {
            margin-bottom: var(--space-s);
            display: block;
        }
    }
    a[href^="tel"] {
        white-space: nowrap;
        @include tablet {
            font-size: var(--step-2);
            font-weight: $weight-bold;
            // font-family: helvetica_neuebold, $family-primary;
            color: $body-color;
        }
    }
}
