.main-nav {
    > li {
        > a {
            text-decoration: none;
            &:hover {
                font-style: italic;
            }
        }
        ul a {
            transition: transform 0.0125s $easing;
            &:hover {
                font-style: italic;
                transform: scale(1.05);
                transform-origin: left;
            }
        }
    }
}
