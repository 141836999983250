// Estils de text per a pàgines bàsiques
.text-base {
	// h2 {}
	// h3 {}
	// …
	//per fitxes
	p + h2,
	ul + h2,
	p + h3,
	ul + h3,
	p + h4,
	ul + h4 {
		margin-top: var(--space-l);
	}
	h2 {
		font-size: var(--step-1);
	}
	h3 {
		font-size: var(--step-1);
	}
	h4 {
		font-size: var(--step-0);
	}
}
/*formularis*/
// .form-body {
// input,select,textarea{
// 	border-radius: 4px;
// 	border-color: #414141;
// }
// input[type="date"]{
// 	width: 160px;
// 	display: block;
// }
// .select, select{
// 	width: 100%;
// }
// .is-checkradio[type=checkbox]+label::before, .is-checkradio[type=radio]+label::before {
// 	background: #fff;
// 	border-color: #414141;
// }
// .field{
// 	padding-top: var(--space-xs);
// }
// .help-form p{
// 	font-size: var(--step--1);
// }
// ul.field{
// 	padding-top: 0;
// }
// ul.field li:before{
// 	content: none;
// }
// .is-right{
// 	float: right;
// }
// label.error {
// 	color: rgb(200, 41, 41);
// 	margin-top: 0.5em;
// 	display: inline-block;
// 	padding: 0.5em 1em 0.5em 3em;
// 	font-size: var(--step--2);
// 	border-radius: 4px;
// 	background: rgba(200, 41, 41, 0.1)
// 		url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCIgZmlsbD0iI0M4MjkyOSI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xMiAyQzYuNDggMiAyIDYuNDggMiAxMnM0LjQ4IDEwIDEwIDEwIDEwLTQuNDggMTAtMTBTMTcuNTIgMiAxMiAyem0xIDE1aC0ydi0yaDJ2MnptMC00aC0yVjdoMnY2eiIvPjwvc3ZnPg==")
// 		6px 50% no-repeat;
// }
// .protecciodades label.error {
// 	margin-top: 0 !important;
// }
// .input.error,
// .is-checkradio.error,
// select.error {
// 	border-color: rgb(200, 41, 41);
// }
// }

.desplegable-destacat-b .text-base {
	h3,
	h4 {
		font-size: var(--step--1);
		margin-bottom: var(--space-2xs);
		margin-top: 0;
	}
	p + h3,
	ul + h3,
	p + h4,
	ul + h4 {
		margin-top: var(--flow-space, 1em);
	}
}

@media screen and (min-width: 1024px) {
	.desplegable-destacat {
		p {
			max-width: 500px;
		}
	}
}
@media screen and (min-width: 1600px) {
	.desplegable-destacat {
		p {
			max-width: 800px;
		}
	}
}
a.download::before {
	content: "\a0";
	display: inline-block;
	background: #363636;
	min-height: 1em;
	width: 20px;
	-webkit-mask-position: 50% 50%;
	mask-position: 50% 50%;
	-webkit-mask-size: 100%;
	mask-size: 100%;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	flex-shrink: 0;
	margin-right: 0.5em;
	-webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMC4zMTMiIGhlaWdodD0iMTguNjIxIj48Zz48ZyBkYXRhLW5hbWU9IkNUQSBEVUJURVMiPjxwYXRoIGQ9Ik0yMC4zMTMgMTYuMDgyVjEyLjdhLjg0Ny44NDcgMCAwIDAtMS42OTMgMHYzLjM4NmEuODQ2Ljg0NiAwIDAgMS0uODQ2Ljg0NkgyLjUzOWEuODQ2Ljg0NiAwIDAgMS0uODQ2LS44NDZWMTIuN0EuODQ3Ljg0NyAwIDAgMCAwIDEyLjd2My4zODZhMi41MzkgMi41MzkgMCAwIDAgMi41MzkgMi41MzloMTUuMjM1YTIuNTM5IDIuNTM5IDAgMCAwIDIuNTM5LTIuNTQzWm0tNS40LTQuNDE4LTQuMjMyIDMuMzg2YS44NDYuODQ2IDAgMCAxLTEuMDUgMEw1LjQgMTEuNjYzYS44NDYuODQ2IDAgMCAxIDEuMDUtMS4zMmwyLjg2IDIuMjg1Vi44NDZhLjg0Ni44NDYgMCAwIDEgMS42OSAwdjExLjc4MmwyLjg2MS0yLjI4NWEuODQ2Ljg0NiAwIDEgMSAxLjA1IDEuMzJaIiBmaWxsPSIjMzYzNjM2Ii8+PC9nPjwvZz48L3N2Zz4=);
	mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMC4zMTMiIGhlaWdodD0iMTguNjIxIj48Zz48ZyBkYXRhLW5hbWU9IkNUQSBEVUJURVMiPjxwYXRoIGQ9Ik0yMC4zMTMgMTYuMDgyVjEyLjdhLjg0Ny44NDcgMCAwIDAtMS42OTMgMHYzLjM4NmEuODQ2Ljg0NiAwIDAgMS0uODQ2Ljg0NkgyLjUzOWEuODQ2Ljg0NiAwIDAgMS0uODQ2LS44NDZWMTIuN0EuODQ3Ljg0NyAwIDAgMCAwIDEyLjd2My4zODZhMi41MzkgMi41MzkgMCAwIDAgMi41MzkgMi41MzloMTUuMjM1YTIuNTM5IDIuNTM5IDAgMCAwIDIuNTM5LTIuNTQzWm0tNS40LTQuNDE4LTQuMjMyIDMuMzg2YS44NDYuODQ2IDAgMCAxLTEuMDUgMEw1LjQgMTEuNjYzYS44NDYuODQ2IDAgMCAxIDEuMDUtMS4zMmwyLjg2IDIuMjg1Vi44NDZhLjg0Ni44NDYgMCAwIDEgMS42OSAwdjExLjc4MmwyLjg2MS0yLjI4NWEuODQ2Ljg0NiAwIDEgMSAxLjA1IDEuMzJaIiBmaWxsPSIjMzYzNjM2Ii8+PC9nPjwvZz48L3N2Zz4=);
}
//imatges laterals arredonides fitxes

#main .col-bg {
	background-color: #eaeaea;
	padding: var(--space-s) var(--space-l);

	column-gap: var(--space-m); /* Gap between columns */
	list-style-type: none; /* Optional: Remove list bullets */
	@include tablet {
		border-radius: 9999px;
		&-2 {
			column-count: 2;
			background: transparent;
		}
		&-4 {
			column-count: 4;
		}
	}
	li {
		break-inside: avoid-column; /* Prevent breaking items across columns */
		padding-left: 0;
		&::before {
			content: none !important;
		}
	}
}
#main .col-li-bg {
	//column-gap: var(--space-m); /* Gap between columns */
	list-style-type: none; /* Optional: Remove list bullets */
	display: flex;
	flex-wrap: wrap;
	margin-left: -1%;
	margin-right: -1%;
	@include tablet {
		&-2 {
			li {
				width: 48%;
			}
		}
		&-4 {
			li {
				width: 23%;
			}
		}
	}
	li {
		break-inside: avoid-column; /* Prevent breaking items across columns */
		background-color: #eaeaea;
		@include tablet {
			border-radius: 9999px;
		}
		padding: var(--space-s) var(--space-l);
		margin: 1em 1%;
		box-sizing: border-box;
		&::before {
			content: none !important;
		}
	}
}
#main details .col-bg,
#main details .list-count,
#main details .col-li-bg {
	font-size: 0.875rem;
}
#main .list-count {
	list-style-position: inside !important;
	margin-left: 0;
	column-gap: var(--space-l); /* Gap between columns */
	@include tablet {
		&-2 {
			column-count: 2;
		}
		&-4 {
			column-count: 4;
		}
	}
	li {
		position: relative;
		list-style: none;
		padding-left: var(--space-l);
		break-inside: avoid-column; /* Prevent breaking items across columns */
		&::before {
			content: counter(list-item);
			font-weight: bold;
			position: absolute;
			font-size: var(--step-3);
			line-height: 1;
			font-weight: 500;
			color: $primary;
			left: 0;
		}
	}
}
@media screen and (min-width: 1024px) {
	.fitxa-imatge::before {
		content: "";
		display: block;
		height: 300px;
		width: 450px;
		border-top-right-radius: 9999px;
		border-bottom-right-radius: 9999px;
		background-size: cover;
		position: absolute;
		left: -40px;
	}
}
@media screen and (min-width: 1200px) {
	.fitxa-imatge::before {
		height: 400px;
		width: 575px;
	}
}
@media screen and (min-width: 1320px) {
	.fitxa-imatge::before {
		width: 42%;
	}
}
@media screen and (min-width: 1408px) {
	.fitxa-imatge::before {
		height: 478px;
		left: 0;
	}
}


.flex-responsive {
    flex-direction: column;
}

@media (min-width: 768px) {
    .flex-responsive {
        flex-direction: row;
    }
}