.pills-list {
    li + li {
        margin-top: var(--space-2xs);
    }
    li:not(:has(a)),
    a {
        display: flex;
        align-items: center;
        gap: var(--space-xs);
        border-radius: $radius-large;
        background-color: rgba(255, 255, 255, 13%);
    }
    li:not(:has(a)) {
        padding: var(--space-3xs) var(--space-s);
    }
    a {
        justify-content: space-between;
        padding: var(--space-2xs) var(--space-xs);
        &::after {
            content: "";
            display: block;
            width: 33px;
            height: 33px;
            opacity: 0.5;
            flex-shrink: 0;
            .desplegable-foto--1 & {
                background-image: url("img/ico-fletxa-blanc.svg");
            }
            .desplegable-foto--2 & {
                background-image: url("img/ico-fletxa-vermell.svg");
            }
        }
        &:hover,
        &:focus {
            background-color: rgba(255, 255, 255, 34%);
            &::after {
                opacity: 1;
            }
        }
    }
}
ol.pills-list {
    li span {
        font-weight: bold;
        font-size: var(--step-2);
    }
}
