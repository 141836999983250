.info-treballa {
    @include desktop {
        &::before {
            content: "";
            display: block;
            // height: 714px;
            height: 660px;
            width: 850px;
            background: url("img/img-guants.jpg") 100% 50% no-repeat;
            border-top-right-radius: $radius-rounded;
            border-bottom-right-radius: $radius-rounded;
            background-size: cover;
            position: absolute;
            left: -270px;
            top: 175px;
            // outline: 1px solid red;
        }
    }
    @include widescreen {
        &::before {
            left: -150;
            // outline: 1px solid blue;
        }
    }
    @include fullhd {
        &::before {
            left: -80px;
            // outline: 1px solid yellow;
        }
    }
}
