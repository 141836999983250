.donacions-list {
    &__counter {
        > * {
            line-height: 1;
        }
        span:last-child {
            position: relative;
            &::before {
                content: "";
                display: block;
                width: 1px;
                height: 40px;
                background-color: #fff;
                position: absolute;
                left: calc((var(--space-s) / 2) * -1);
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    a {
        white-space: nowrap;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
