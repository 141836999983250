[class*="boxes-list-"] {
    // Declaració de variables:
    // Espai entre columnes:
    --gap: var(--space-xs);
    // Número de columnes:
    --columns: 1;

    gap: var(--gap);
    display: flex;
    flex-wrap: wrap;
    li {
        flex: none;
        width: 100%;
        padding: var(--space-xs) !important;
        margin-top: 0;
        width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
        border: 2px solid $primary;
        &::before {
            display: none !important;
        }
    }
    ::marker {
        content: "";
        font-size: 0; /* Safari support is limited to color and font-size. See bug https://bugs.webkit.org/show_bug.cgi?id=204163 */
    }
}
.boxes-list-cols-1 {
    li {--columns: 1;}
}
.boxes-list-cols-2 {
    li {
        @include tablet {
            --columns: 2;
        }
    }
}
.boxes-list-cols-3 {
    li {
        @include tablet {
            --columns: 3;
        }
    }
}
.boxes-list-cols-4 li {
    @include tablet {
        --columns: 3;
    }
    @include desktop {
        --columns: 4;
    }
}