.breadcrumb-nav {
    ol {
        list-style: none;
        margin-left: 0;
		/*padding-bottom: .45em;
		margin-top: -.45em;*/
    }
    li {
        margin-top: 0;
        padding-left: 0 !important;
        flex-shrink: 0;
        &::after {
            content: "·";
            padding-left: var(--space-2xs);
        }
        &:last-child::after {
            content: "";
        }
    }
    a {
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
//arregla problema a la navgeació que els offset provocaven
.breadcrumb-nav + .columns {
	pointer-events: none;
}
.breadcrumb-nav + .columns a{
	pointer-events: all;
}