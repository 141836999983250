.area-privada {
    a {
        text-decoration: none;
        &::before {
            content: "";
            display: block;
            width: 28px;
            height: 28px;
            background:
                url("img/ico-locker-closed.svg"),
                url("img/ico-locker-open.svg") 100% 50% no-repeat;
            background-repeat: no-repeat;
            background-position:
                100% 50%,
                -28px 50%;
        }
        &:hover,
        &:focus {
            color: #fff;
            // font-style: italic;
            text-decoration: underline;
            &::before {
                background-position:
                    -18px 50%,
                    100% 50%;
            }
        }
    }
    &--btn {
        a {
            &::before {
                width: 20px;
                height: 20px;
                background-size: 13px 20px;
            }
            &:hover {
                text-decoration: none;
            }
            &:hover,
            &:focus {
                background-color: $primary !important;
                color: #fff !important;
                &::before {
                    background-size: 20px 20px;
                    background-position:
                        -20px 50%,
                        100% 50%;
                }
            }
        }
    }
}
