.rounded-img {
    img {
        height: 25rem;
        object-fit: cover;
    }
    &--left {
        img {
            border-top-right-radius: $radius-rounded;
            border-bottom-right-radius: $radius-rounded;
            margin-left: calc(var(--space-xl) * -1);
        }
    }
    &--right {
        img {
            border-top-left-radius: $radius-rounded;
            border-bottom-left-radius: $radius-rounded;
        }
    }
    .recerca & {
        margin: -10rem 0;
    }
}
