.share-list {
    li {
        border-right: 1px solid $grey-light;
        &:last-child {
            border-right: none;
        }
    }
    a {
        transition: opacity .1s ease-in;
        &:hover {
            opacity: .6;
        }
    }
}
