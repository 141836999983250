.highlight-title {
    background-color: $primary;
    color: #fff;
    border-radius: $radius-large;
    padding: var(--space-2xs) var(--space-m);
    display: inline-block;
    line-height: 1.1;
    max-width: fit-content;
	@include tablet {
		line-height: 1.4;
		padding-top: 0;
   		 padding-bottom: 0;
	}
}
