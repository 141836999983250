.tag {
    font-weight: $weight-bold;
    text-decoration: none;
    // font-family: helvetica_neuebold, $family-primary;
    &--icon {
        width: 6.25rem;
        height: 2.1875rem !important;
        img {
            margin-right: 5px;
        }
    }
    &--wide {
        width: 9.5rem;
    }
}
