.info-consell {
    @include mobile {
        > div > div {
            flex-direction: column;
            > div {
                &::after {
                    position: static !important;
                    height: 1px !important;
                    width: 100% !important;
                    margin-top: var(--space-m);
                }
            }
        }
    }
    > div > div > div {
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            bottom: -25%;

            height: 1px;
            background-color: #fff;
        }
        &:last-child::after {
            display: none;
        }
    }
}
.vocals ul{
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: wrap;
    li{
        padding-left: 0;
        margin: 0 0 1rem;
		width: 100%;
		@media screen and (min-width: 700px) {
        width: 33%;
		}
        &:before{
            content: none;
        }
    }
}

.inline ul, ul.inline{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    gap:1em;
    li{
        padding-left: 0;
        margin: 0 0 1rem;
        a{
            display: flex;
        }
        &:before{
            content: none;
        }
    }
}
.icon-email-usuari{
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NCIgaGVpZ2h0PSI0NCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMmMzZTUwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41IiBjbGFzcz0iaWNvbiBpY29uLXRhYmxlciBpY29uLXRhYmxlci1tYWlsIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIHN0cm9rZT0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGQ9Ik0zIDdhMiAyIDAgMCAxIDItMmgxNGEyIDIgMCAwIDEgMiAydjEwYTIgMiAwIDAgMS0yIDJINWEyIDIgMCAwIDEtMi0yVjd6Ii8+PHBhdGggZD0ibTMgNyA5IDYgOS02Ii8+PC9zdmc+) no-repeat 0 0;
}