.info-title {
    a {
        text-decoration: none;
        position: relative;
        &:hover {
            text-decoration: underline;
        }
        &::after {
            content: "";
            display: block;
            width: 22px;
            height: 22px;
            background: url("img/ico-info.svg") no-repeat;
            position: absolute;
            left: 100%;
            top: -11px;
        }
    }
}
