.desplegable-destacat {
    // backdrop-filter en pseudoelement per evitar problema en Chrome (https://stackoverflow.com/questions/60997948/backdrop-filter-not-working-for-nested-elements-in-chrome)
    &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(208, 206, 206, 0.7);
        backdrop-filter: blur(15px);
        z-index: -1;
        border-radius: $radius-large;
    }
    hr {
        background-color: #fff;
    }
}
