/* Override Bulma icon margin styles */
.button .icon:first-child {
    margin: 0 0.5em 0 0 !important;
}

.button {
    font-weight: bold;
    // font-family: "helvetica_neuebold", $family-primary;
    font-size: var(--step--2);
    height: auto;
    text-decoration: none;
    // redefinim el color de l'outline, ja que per defecte és 'currentColor' i no es veu quan el color de text és blanc.
    &:focus,
    &:focus-visible {
        outline-color: $black !important;
    }
    // &.is-primary {
    //     &:hover,
    //     &:focus {
    //         color: $dark;
    //         background-color: $primary-light;
    //     }
    // }
    &--roll {
        overflow: hidden;
        position: relative;
        display: inline-block;
        height: 2.5rem;
        padding-top: 0;
        padding-bottom: 0;
        &::before {
            content: "";
            display: block;
            position: absolute;
            border-radius: $radius-rounded;
            border: 2px solid transparent;
            left: -1px;
            top: -1px;
            bottom: -1px;
            width: 0;
            transition: 0.3s;
            .main-header & {
                display: none;
            }
        }
        span {
            display: block;
            height: 100%;
            position: relative;
            top: -100%;
            transition: 0.3s;
            line-height: 2.5rem;
        }
        &:hover,
        &:focus {
            // opacity: 0.65;
            &::before {
                border-color: #fff;
                width: calc(100% + 2px);
            }
            .main-header & {
                color: $grey-darker !important;
                background: rgba(#fff, 0.66) !important;
                backdrop-filter: blur(37px);
            }
            span {
                top: 0%;
            }
        }
    }
    &.is-disabled {
        cursor: default;
    }
}
// p.button {
//     display: block;
//     width: max-content;
//     padding-top: 18px;
// }
