.info-donacions {
    @include mobile {
        > div > div {
            flex-direction: column;
            > div {
                &::after {
                    position: static !important;
                    height: 1px !important;
                    width: 100% !important;
                    margin-top: var(--space-m);
                }
            }
        }
    }
    > div > div > div {
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            right: calc(var(--space-l) / 2 * -1);
            top: 0;
            width: 1px;
            height: 100%;
            background-color: #fff;
        }
        &:last-child::after {
            display: none;
        }
    }
}
