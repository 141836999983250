.flow {
    --flow-space: var(--space-s);
    > * + * {
        margin-top: var(--flow-space, 1em);
    }
    &--smaller {
        --flow-space: var(--space-3xs);
    }
    &--small {
        --flow-space: var(--space-2xs);
    }
    &--large {
        --flow-space: var(--space-l);
    }
    &--larger {
        --flow-space: var(--space-xl);
    }
    &--largest {
        --flow-space: var(--space-3xl);
    }
}
