.has-submenu {
    transition-duration: 0.5s;
    transition-duration: 0.5s;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    @include widescreen {
        position: relative;
    }
    > ul {
        flex-basis: 100%;
        .js & {
            transition: all 0.5s ease;
            display: none;
            opacity: 0;
            @include widescreen {
                min-width: 15rem;
            }
        }
        ul {
            margin-left: 0;
        }
    }
    &.open {
        > ul {
            display: block;
            z-index: 2;
            opacity: 1;
        }
    }
    button {
        background: transparent;
        border: 0;
        padding: 0;
        // cursor: pointer;
        // @include until-widescreen {
        //     margin-left: auto;
        // }
        > span {
            padding: 12px 15px 12px 0;
            width: 1rem;
            height: 1rem;
            display: block;
            box-sizing: content-box;
            @include widescreen {
                padding: 10px;
            }
            &::after {
                content: "";
                display: block;
                width: inherit;
                height: inherit;
                background-image: url("img/ico-flecha-menu.svg");
                background-position: 50%;
                background-repeat: no-repeat;
                transform: rotate(0.5turn);
            }
        }
        &[aria-expanded="true"] {
            span::after {
                transform: none;
            }
        }
    }
}

.dropdown {
    a {
        text-decoration: none;
        // color: #fff;
        display: block;
        &:hover,
        &:focus {
            color: $primary;
        }
    }
    // @include until-widescreen {
    //     padding-bottom: var(--space-s);
    // }
    // @include widescreen {
    //     background-color: #000;
    //     border: solid #fff;
    //     border-width: 0 1px 1px;
    //     padding: var(--space-s) !important;
    // }
    // .js & {
    //     @include widescreen {
    //         position: absolute;
    //         left: 0;
    //         right: 0;
    //         top: 100%;
    //     }
    // }
}
