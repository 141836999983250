.ap-icones {
    li > div {
        background-color: rgba(255, 255, 255, 0.44);
        border-radius: 9px;
        > div:first-child {
            &::after {
                content: "";
                width: 1px;
                display: block;
                background-color: #000;
                margin-left: var(--space-2xs);
            }
        }
        &.has-background-primary {
            > div:first-child {
                &::after {
                    display: none;
                }
            }
        }
    }
}
