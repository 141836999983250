.vols-donar{
	table{
		border-collapse: separate !important;
		border-spacing: 0;


		@include tablet {
			display: table;
			font-size: 1rem;
		}
		tr{
			th{
				background-color: transparent;
				color:#000;
				border-right:solid 1px #000;
				border-bottom:solid 1px #000;
				min-width: auto;

				padding: 12px;

				font-weight: bold;
				&.nb{
					border:none;
				}
				&.b-l{
					border-left:solid 1px #000;
				}
				&.b-t{
					border-top:solid 1px #000;
				}
				&.titol1,&.titol2{
					background-color: #fdc300;
					background:rgba(235,195,0,.6);
					border:solid 1px #000;
				}
				&.titol1{
					border-top-left-radius: 6px;
					border-top-right-radius: 6px;
				}
				&.titol2{
					border-top-left-radius: 6px;
					border-bottom-left-radius: 6px;
				}
			}
			td{
				border-right:solid 1px #000;
				border-bottom:solid 1px #000;

				@include tablet {
					padding: 8px 12px;
				}
				text-align: center;
				&.br-r{
					border-bottom-right-radius: 6px;
				}
			}
		}
	}
	.table tbody tr:last-child td, .table tbody tr:last-child th, table tbody tr:last-child td, table tbody tr:last-child th {
		border-bottom-width: 1px;
	}
	&.sang{
		table{
			border-collapse: separate !important;
			border-spacing: 0;


			@include tablet {
				display: table;
				font-size: 1rem;
			}
			tr{
				th{

					&.titol1,&.titol2{
						background-color: #edc4ca;
						background:rgba(171,25,45,.35);
						border:solid 1px #000;
					}
				}
			}
		}
	}
	&.plasma{
		table{
			border-collapse: separate !important;
			border-spacing: 0;


			@include tablet {
				display: table;
				font-size: 1rem;
			}
			tr{
				th{

					&.titol1,&.titol2{
						background-color: #fdc300;
						background:rgba(235,195,0,.6);
						border:solid 1px #000;
					}
				}
			}
		}
	}
}
.plasma{
	table{
		border-collapse: separate !important;
		border-spacing: 0;


		@include tablet {
			display: table;
			font-size: 1rem;
		}
		tr{
			th{
				background-color: transparent;
				color:#000;
				border-right:solid 1px #000;
				border-bottom:solid 1px #000;
				min-width: auto;

				padding: 12px;

				font-weight: bold;
				&.nb{
					border:none;
				}
				&.b-l{
					border-left:solid 1px #000;
				}
				&.b-t{
					border-top:solid 1px #000;
				}
				&.titol1,&.titol2{
					background-color: #fdc300;
					background:rgba(235,195,0,.6);
					border:solid 1px #000;
				}
				&.titol1{
					border-top-left-radius: 6px;
					border-top-right-radius: 6px;
				}
				&.titol2{
					border-top-left-radius: 6px;
					border-bottom-left-radius: 6px;
				}
			}
			td{
				border-right:solid 1px #000;
				border-bottom:solid 1px #000;

				@include tablet {
					padding: 8px 12px;
				}
				text-align: center;
				&.br-r{
					border-bottom-right-radius: 6px;
				}
			}
		}
	}
	.table tbody tr:last-child td, .table tbody tr:last-child th, table tbody tr:last-child td, table tbody tr:last-child th {
		border-bottom-width: 1px;
	}
}
