.site-logo {
    height: 2.8125rem;
    font-size: 1rem;
    @media screen and (max-width: 40em) {
        .js-menu-open & {
            svg {
                width: 34px;
            }
        }
    }
}
