.materna{
	.boxes-grid svg{
		fill:#cc28b0;
		width: 60px;
		height: auto;
		max-height: 60px;
		margin-top: -30px;
		margin-bottom: 1em;
	}
	.highlight-title{
		background-color:#cc28b0;
	}
	.motius-list svg{
		fill:#cc28b0;
		stroke:#cc28b0;
		width: 40px;
		height: auto;
		@media screen and (max-width: 768px) {
			left: 1.75rem!important;
			top: -.75rem!important;
		}

		@include tablet {
			width: 54px;
		}
	}
	.motius-list svg path{
		stroke:#cc28b0;

	}
	.motius-list svg g{

		stroke:#cc28b0;

	}
	.top-img {
		object-position: 50% 9%;
	}
	#mapid {
		height: 340px;
		@include tablet {
			height: 640px;
		}
	}
			#llistatHospitals {  overflow-y: auto; height: 530px; }
			.destacat { background-color: yellow; }

	.filets--donacions-sang {
	top: -30px;
	}
	.inscdonacionshospitals #dades-reserva table.calendari td{
		color:#000;
		width:14.2%;
		text-align: left;

	   }
	   .inscdonacionshospitals #dades-reserva table.calendari td {
		   color: #000;
		   width: 14.2%;
		   text-align: left;
		   border: solid 1px #efefef;
	   }

	   /*.inscdonacions .calendari th, .inscdonacionshospitals .calendari th {
		   text-align: left;
	   }*/
	   .inscdonacionshospitals #dades-reserva table.calendari td small{
	   display: block;
		line-height: 1;
	   }
	   #calendar-container{
		overflow-x: auto;
		max-width: 100%;
	   }

	   table { width: 100%!important ; border-collapse: collapse; }
	   th, td { border: 1px solid #ddd; padding: 10px; text-align: center; }
	   td { border: 1px solid #ddd; padding: 10px; text-align: center; height:85px;}
	   th { background-color: #f4f4f4; }
	   .navegacio { text-align: center; margin-bottom: 20px;    position: absolute;
		   width: 100%; }
		   .navegacio .button{
			   background: transparent;
			   margin-right: 2em;
			   padding: .5em 1em;
		   }
	   .mes { display: none; } /* Amaguem tots els mesos */
	   .visible { display: block; } /* Només el mes visible */
	   #anterior, #seguent { display: none; } /* Amaguem inicialment els botons */
}

/*@media (max-width: 600px) {

    table.calendari thead {
        display: none;
    }
	.inscdonacionshospitals #dades-reserva table.calendari td {
		color: #000;
		width: 100%!important;
		text-align: left;
		border: none!important;
	}
	table.calendari td.empty,table.calendari td:empty {
        display: none;
    }

    table.calendari,
    table.calendari tbody,
    table.calendari tr,
    table.calendari td {
        display: block;
        width: 100%;
    }


    table.calendari td small {
        display: block;
        font-size: 0.85rem;
        color: #666;
        margin-top: 5px;
    }
}*/