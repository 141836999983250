.pills-nav {
    li {
        @include mobile {
            flex-basis: calc(50% - (var(--space-xs) / 2));
        }
        margin-top: 0 !important;
        padding-left: 0 !important;
        &::before {
            display: none !important;
        }
    }
    a {
        text-decoration: none;
        text-align: center;
        &:hover,
        &:focus {
            background-color: $primary !important;
            color: #fff;
        }
    }
	&.submenu{
		.active{
			img{
				filter: brightness(255);
			}
		}
		a{
			&:hover,
			&:focus {
				background-color: $grey-dark !important;
				color: #fff;
				img{
					filter: brightness(255);
				}
			}

		}
	}
}
