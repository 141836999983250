.is-checkradio[type="checkbox"],
.is-checkradio[type="radio"] {
    display: inline-block;
    &:focus + label::before {
        // @include focus;
        border-color: $primary;
        border-width: 2px;
    }
    + label {
        // font-size: var(--step--1);
        &::before {
            // width: 1.25rem;
            // height: 1.25rem;
            // top: 50%;
            // transform: translateY(-50%);
            background: #fff;
        }
    }
    &:checked {
        // + label {
        //     color: $primary;
        //     &::before {
        //         border-color: $primary;
        //     }
        // }
    }
    // .has-background-primary & {
    //     + label {
    //         &::before {
    //             border-color: #fff;
    //         }
    //         &::after {
    //             filter: brightness(0);
    //         }
    //         &:hover {
    //             color: inherit;
    //             &::before {
    //                 border-color: #fff !important;
    //             }
    //         }
    //     }
    //     &:checked + label {
    //         color: inherit;
    //     }
    //     &:focus + label::before {
    //         border-color: #000;
    //     }
    // }
}

// .is-checkradio[type="checkbox"] {
//     + label {
//         &::after {
//             width: 21px;
//             height: 14px;
//             background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEuMTYxIiBoZWlnaHQ9IjE0LjE4OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSA3LjAxMmMyLjc1NCAyLjQzMSA0LjU1NSA0LjQ5IDUuNDA0IDYuMTc2QzEwLjQ4MSA4LjI4NSAxNS4wNjcgNC4yMjIgMjAuMTYxIDEiIHN0cm9rZT0iI0ZGODkwMCIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==");
//             left: 3px;
//             top: 3px;
//             transform: none;
//             border: 0;
//         }
//     }
// }

// .is-checkradio[type="radio"] {
//     + label {
//         &::before {
//             border-radius: 0;
//         }
//     }
//     &:checked {
//         + label {
//             &::after {
//                 border-radius: 0;
//                 width: 0.75rem;
//                 height: 0.75rem;
//                 top: 0.45rem;
//                 left: 0.25rem;
//                 transform: none;
//             }
//         }
//     }
// }
