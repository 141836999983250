.slider {
    margin-bottom: 0;
    .js & {
        &:not(.slick-initialized) {
            display: none;
        }
    }
}
ul.slick-dots {
    li {
        padding-left: 0;
        &::before {
            display: none;
        }
    }
}
