.slider-news {
    margin-bottom: 0;
    .slick-list {
        overflow: visible;
    }
    .slick-track {
        display: flex;
        // &:has(.slider-news__box:hover) {
        //     display: block; /* Evita que les caixes que no tenen hover augmentin l'alçada al fer hover */
        // }
    }
    .slick-slide {
        height: auto;
        > div {
            padding: 0 $column-gap;
            height: 100%;
            > div {
                height: 100%;
                position: relative;
                > div {
                    height: 100%;
                }
            }
        }
    }
    &__box {
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.3);
            border-radius: $radius-large;
            transition: background-color 0.125s $easing;
        }
        &:hover {
            position: absolute;
            height: min-content !important;
            &::before {
                background: rgba(0, 0, 0, 0.7);
            }
            .slider-news__info {
                @media (hover: hover) {
                    max-height: 500px;
                }
            }
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 26px;
            height: 26px;
            background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23FFF' cx='13' cy='13' r='13'/%3E%3Cg fill='%23AB192D'%3E%3Cpath d='M12 6h2v14h-2z'/%3E%3Cpath d='M20 12v2H6v-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            top: var(--space-xs);
            right: var(--space-xs);
        }
        .tags {
            z-index: 15;
        }
        time {
            z-index: 5;
        }
        h3 {
            z-index: 10;
            // limita text a 3 línies
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            a {
                display: block;
                text-decoration: none;
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: $radius-large;
                }
            }
        }
    }
    &__info {
        z-index: 5;
        max-height: 0;
        overflow: hidden;
        // transition: max-height 0.125s $easing;
    }
}
