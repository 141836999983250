.dest-list {
    font-weight: normal;
    font-size: var(--step--1);
    margin-left: 0 !important;
    ::marker {
        content: "";
        font-size: 0; /* Safari support is limited to color and font-size. See bug https://bugs.webkit.org/show_bug.cgi?id=204163 */
    }
    a {
        color: $primary;
        display: block;
        background-color: $primary-light;
        border-radius: $radius;
        padding: var(--space-xs) var(--space-s);
    }
    li + li {
        margin-top: var(--space-2xs) !important;
    }
}