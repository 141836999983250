/* @link https://utopia.fyi/type/calculator?c=320,18,1.2,1240,20,1.25,7,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
    /* Step -2: 12.50px → 12.80px */
    --step--2: clamp(0.7813rem, 0.7747rem + 0.0326vw, 0.8rem);

    /* Step -1: 15.00px → 16.00px */
    --step--1: clamp(0.9375rem, 0.9158rem + 0.1087vw, 1rem);

    /* Step 0: 18.00px → 20.00px */
    --step-0: clamp(1.125rem, 1.0815rem + 0.2174vw, 1.25rem);

    /* Step 1: 21.60px → 25.00px */
    --step-1: clamp(1.35rem, 1.2761rem + 0.3696vw, 1.5625rem);

    /* Step 2: 25.92px → 31.25px */
    --step-2: clamp(1.62rem, 1.5041rem + 0.5793vw, 1.9531rem);

    /* Step 3: 31.10px → 39.06px */
    --step-3: clamp(1.9438rem, 1.7707rem + 0.8652vw, 2.4413rem);

    /* Step 4: 37.32px → 48.83px */
    --step-4: clamp(2.3325rem, 2.0823rem + 1.2511vw, 3.0519rem);

    /* Step 5: 44.79px → 61.04px */
    // --step-5: clamp(2.7994rem, 2.4461rem + 1.7663vw, 3.815rem);

    /* Step 6: 53.75px → 76.29px */
    --step-6: clamp(3.3594rem, 2.8694rem + 2.45vw, 4.7681rem);

    /* Step 7: 64.50px → 95.37px */
    // --step-7: clamp(4.0313rem, 3.3602rem + 3.3554vw, 5.9606rem);

    // Ajustos deguts al canvi de font d'Helvetica Neue a Roboto
    // 44.79px → 58px
    --step-5: clamp(2.7994rem, 2.5122rem + 1.4359vi, 3.625rem);
    // 64.50px → 88px
    --step-7: clamp(4.0313rem, 3.5204rem + 2.5543vi, 5.5rem);
}
