@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(../../fonts/Roboto-normal-300.woff2) format('woff2'), url(../../fonts/Roboto-normal-300.ttf) format('truetype'), url(../../fonts/Roboto-normal-300.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Roboto-normal-400);
  src: local('Roboto'), url(../../fonts/Roboto-normal-400.svg#Roboto) format('svg'), url(../../fonts/Roboto-normal-400.woff2) format('woff2'), url(../../fonts/Roboto-normal-400.ttf) format('truetype'), url(../../fonts/Roboto-normal-400?#iefix) format('embedded-opentype'), url(../../fonts/Roboto-normal-400.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(../../fonts/Roboto-normal-500.woff2) format('woff2'), url(../../fonts/Roboto-normal-500.ttf) format('truetype'), url(../../fonts/Roboto-normal-500.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(../../fonts/Roboto-normal-700.woff2) format('woff2'), url(../../fonts/Roboto-normal-700.ttf) format('truetype'), url(../../fonts/Roboto-normal-700.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url(../../fonts/Roboto-italic-300.woff2) format('woff2'), url(../../fonts/Roboto-italic-300.ttf) format('truetype'), url(../../fonts/Roboto-italic-300.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url(../../fonts/Roboto-italic-400.woff2) format('woff2'), url(../../fonts/Roboto-italic-400.ttf) format('truetype'), url(../../fonts/Roboto-italic-400.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url(../../fonts/Roboto-italic-700.woff2) format('woff2'), url(../../fonts/Roboto-italic-700.ttf) format('truetype'), url(../../fonts/Roboto-italic-700.woff) format('woff');
}

