.boxes-grid-variable {
    @include desktop {
        --columnGap: var(--space-m) !important;
    }
    .column {
        @media screen and (max-width: 28em) {
            width: 100%;
        }
    }
}
