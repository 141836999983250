.footer {
    > div:first-child {
        .a-privada & {
            @include desktop {
                padding-right: 290px;
            }
            @media screen and (min-width: 1760px) {
                padding-right: 0;
            }
        }
    }
    &__container {
       // background: url("img/footer-bg-small.jpg") no-repeat top center;
	   background: rgb(165,189,212);
		background: linear-gradient(175deg, rgba(165,189,212,1) 0%, rgba(227,230,233,1) 100%);

        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        background-size: 48rem auto;
        @include tablet {
           // background-image: url("img/footer-bg-medium.jpg");
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            background-size: 73rem auto;
        }
        @include desktop {
           // background-image: url("img/footer-bg-large.jpg");
			background-image: url("img/footer-bg-largerv2.webp");
            border-top-left-radius: 60px;
            border-top-right-radius: 60px;
            background-size: cover;
            > .wrapper {
                height: 55rem;
                position: relative;
            }
			.footer-opacity{
				background-image: linear-gradient(to bottom,rgba(106, 150, 193, 0.6), rgba(0, 0, 0, 0));
				position:absolute;
				height:30%;
				width:100%;top: 0;
				border-radius: 60px;
			}
        }
        @include widescreen {
            //background-image: url("img/footer-bg-larger.jpg");
			background-image: url("img/footer-bg-largerv2.webp");
           // background-position: 50% 20%;
			background-position: 50% 40%;
			.footer-opacity{
				background-image: linear-gradient(to bottom,rgba(106, 150, 193, 0.6), rgba(0, 0, 0, 0));
				position:absolute;
				height:30%;
				width:100%;top: 0;
				border-radius: 60px;
			}
        }
        .a-privada & {
            @include desktop {
                padding-right: 290px;
            }
            @media screen and (min-width: 1760px) {
                padding-right: 0;
            }
        }
    }
    a {
        color: #000;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    hr {
        background-color: $primary;
        height: 2px;
    }
    &__wrapper {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        padding-left: var(--space-l-xl);
        padding-right: var(--space-l-xl);
        background-color: rgb(255 255 255 / 0.75);
        backdrop-filter: blur(12px);
        @include touch {
            padding-bottom: var(--space-2xl-3xl) !important;
        }
        @include tablet {
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            backdrop-filter: blur(17px);
        }
        @include desktop {
            border-top-left-radius: 60px;
            border-top-right-radius: 60px;
            position: absolute;
            bottom: 0;
            left: var(--space-l);
            right: var(--space-l);
        }
        img {
            width: 11.25rem;
            @include mobile {
                margin-left: auto;
                margin-right: auto;
            }
        }
        hr {
            @include mobile {
                + div ul:first-of-type {
                    > li + li {
                        margin-top: var(--space-s);
                    }
                }
            }
            @include tablet {
                + div {
                    ul:first-of-type {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
            }
            @include desktop {
                + div {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    gap: var(--space-s);
                }
            }
            @include widescreen {
                + div {
                    justify-content: space-between;
                }
            }
        }
    }
    &__tools {
        @include mobile {
            justify-content: center !important;
        }
        @include tablet {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    &__mobile-nav {
        summary::-webkit-details-marker {
            display: none;
        }
        summary {
            list-style: none;
            display: flex;
            align-items: center;
            gap: 5px;
            &::after {
                content: "";
                width: 13px;
                height: 12px;
                display: inline-block;
                background-image: url("data:image/svg+xml,%3Csvg width='13' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 0 13 12H0z' fill='%23AB192D' fill-rule='nonzero'/%3E%3C/svg%3E");
                transform: rotate(0.25turn);
                transition: transform 0.125s $easing;
            }
        }
        details[open] > summary {
            &::after {
                transform: rotate(0.5turn);
            }
        }
    }
    button {
        text-decoration: none !important;
        gap: 5px;
        &::before {
            content: "";
            width: 15px;
            height: 13px;
            display: inline-block;
            background-image: url("data:image/svg+xml,%3Csvg width='15' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 0 15 13H0z' fill='%23AB192D' fill-rule='nonzero'/%3E%3C/svg%3E");
            // transition: transform 0.125s $easing;
        }
        &[aria-expanded="true"] {
            &::before {
                transform: rotate(0.5turn);
            }
        }
        &:hover,
        &:focus {
            background-color: transparent !important;
            text-decoration: underline !important;
        }
    }
    &__btns {
        position: fixed;
        z-index: 10;
        bottom: 0;
        width: 100%;
        background-color: $body-background-color;
        box-shadow: 0px -3px 4px #0000001c;
    }
}
a.nowrap{
	text-wrap-mode: nowrap;
}