.video-link {
    position: relative;
    display: block;
    transition: opacity 0.125s $easing;
    &::after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 53px;
        height: 53px;
        background-image: url("data:image/svg+xml,%3Csvg width='53' height='53' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23FFF' cx='26.5' cy='26.5' r='26.5'/%3E%3Cpath fill='%23AB192D' d='M38 26.5 21 35V18z'/%3E%3C/g%3E%3C/svg%3E");
    }
    &:hover,
    &:focus {
        opacity: 0.9;
    }
}
