.skip-link {
  position: absolute;
  top: var(--step-1);
  right: 100%; /* moves off screen */
  background-color: #fff;
  padding: var(--step-0);
  font-size: 1rem;
  text-decoration: none;
  color: #000 !important;
  font-weight: normal;

  &:focus {
    right: auto;
    left: var(--step-1);
    z-index: 10;
  }
}
