#gdpr-cookie-message {
    position: fixed;
    right: 0;
    bottom: var(--space-l);
    max-width: 37rem;
    // opacity: 0.9;
    box-shadow:
        0px 0px 0.6px rgba(0, 0, 0, 0.02),
        0px 0px 2.4px rgba(0, 0, 0, 0.028),
        0px 0px 6.3px rgba(0, 0, 0, 0.035),
        0px 0px 14px rgba(0, 0, 0, 0.042),
        0px 0px 30.6px rgba(0, 0, 0, 0.05),
        0px 0px 80px rgba(0, 0, 0, 0.07);
    z-index: 9999;
    [role="list"] {
        list-style: none;
    }
    @include mobile {
        top: 0;
        bottom: auto;
        max-width: none;
    }
}
