.main-header {
    .js & {
        position: fixed;
    }
    width: 100%;
    z-index: 1000;
    padding-top: var(--space-s-m);
    &__container {
        // backdrop-filter en pseudoelement per evitar problema en Chrome (https://stackoverflow.com/questions/60997948/backdrop-filter-not-working-for-nested-elements-in-chrome)
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: inherit;
            background: rgba(0, 0, 0, 0.65);
            backdrop-filter: blur(26px);
            z-index: -1;
        }
        &:first-of-type {
            padding-left: var(--space-l);
            &::after {
                border-top-right-radius: $radius-rounded;
                border-bottom-right-radius: $radius-rounded;
            }
            @media screen and (max-width: 30em) {
                .js-menu-open & {
                    flex-grow: 0 !important;
                    padding-right: var(--space-m) !important;
                }
            }
        }
        &:last-of-type {
            padding-right: var(--space-l);
            flex-basis: 7.1875rem;
            &::after {
                border-top-left-radius: $radius-rounded;
                border-bottom-left-radius: $radius-rounded;
            }
            @media screen and (max-width: 30em) {
                .js-menu-open & {
                    flex-grow: 1;
                    flex-basis: auto;
                }
            }
            @include mobile {
                .js-menu-open & {
                    flex-basis: calc(14.6875rem - var(--space-l));
					min-width: 40%;
                }
            }
            @include tablet {
                flex-basis: 18.125rem;
            }
            .js-menu-open & {
                &::after {
                    border-top-left-radius: $radius-large;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
}
