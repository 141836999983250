.info-dest {
    @include mobile {
        .column {
            &:first-child {
                margin-bottom: var(--space-2xs);
            }
        }
    }
    @include tablet {
        .column {
            &:first-child {
                > div {
                    padding-right: var(--space-xl) !important;
                }
            }
            &:last-child {
                margin-left: -4.5rem;
            }
        }
    }
}
