.info-productes-i-serveis {
    @include desktop {
        &::before {
            content: "";
            display: block;
            height: 478px;
            width: 850px;
            background: url("img/productes-i-serveis-foto-1.jpg") no-repeat;
            border-top-right-radius: $radius-rounded;
            border-bottom-right-radius: $radius-rounded;
            background-size: cover;
            position: absolute;
            left: -360px;
        }
    }
	@media screen and (min-width: 1100px) {
		&::before {
			left: -300px;
		}
	}
	@media screen and (min-width: 1250px) {
		&::before {
			left: -240px;
		}
	}
    @include fullhd {
        &::before {
            left: -150px;
        }
    }

	@media screen and (min-width: 1600px) {
		&::before {
            left: 0;
        }
	}
}
