.boxes-grid {
    .column {
        @media screen and (max-width: 35em) {
            width: 100%;
        }
    }
    &--1 {
        &__item {
            a,
            div {
                // &:hover,
                // &:focus {
                //     > span:first-child {
                //         opacity: 0.6;
                //     }
                // }
                > span,
                > div {
                    &:first-child {
                        border-top-left-radius: $radius-large;
                        border-top-right-radius: $radius-large;
                        overflow: hidden;
                        // transition: opacity 0.125s $easing;
                    }
                    &:last-child {
                        border-bottom-left-radius: $radius-large;
                        border-bottom-right-radius: $radius-large;
                    }
                }
            }
            img,
            video {
                object-fit: cover;
                width: 100%;
            }
        }
        a {

			&:hover{
				.has-background-primary{
					background-color: $primary-darker!important;
				}
			}
			.has-background-primary {
	            // position: relative;
				transition: background-color 0.2s $easing;
	            &::after {
	                content: "";
	                display: block;
	                // position: absolute;
	                // right: var(--space-m);
	                // bottom: var(--space-m);
	                width: 30px;
	                height: 30px;
	                margin-left: auto;
	                margin-top: auto;
	                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23eaeaea'/%3E%3Cg fill='none' stroke='%23ab192d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M7.5,18h16' transform='translate(-.5 -2.998)'/%3E%3Cpath d='M18,7.5l6.373,7.7L18,22.9' transform='translate(-1.373 -.199)'/%3E%3C/g%3E%3C/svg%3E");
	            }
	        }
		}
    }
    &--2 {
        &__item {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='41.467' height='41.467'%3E%3Cg transform='translate(-398.698 -1391.313)'%3E%3Ccircle cx='20.733' cy='20.733' r='20.733' transform='translate(398.698 1391.313)' fill='%23ab1a2d'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2' d='M428.856 1412.674h-18.849'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2' d='M419.431 1403.252v18.849'/%3E%3C/g%3E%3C/svg%3E");
            background-position: var(--space-s) calc(100% - var(--space-s));
            background-repeat: no-repeat;
            transition: background-color 0.125s $easing;
            &.has-background-white {
                &:hover {
                    background-color: rgba(#dbdbdb, 0.9) !important;
                }
            }
            &.has-background-grey-lighter {
                &:hover {
                    background-color: rgba(255, 255, 255, 0.8) !important;
                }
            }
            a {
                text-decoration: none;
            }
        }
        h3 {
            top: -0.5em;
        }
        &-icons {
            h3 {
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 110%;
                    bottom: 50%;
                }
            }
            > div {
                &:nth-child(1) {
                    h3::after {
                        width: 24px;
                        height: 42px;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.393' height='41.543' viewBox='0 0 24.393 41.543'%3E%3Cg transform='translate(-1215.067 -2952.651)'%3E%3Cpath d='M112.261,3.066l-10.62,20.46.544,11.38,6.707,5.21h7.856l4.925-4,2.32-6.908-1.034-6.675L119.449,13.2Z' transform='translate(1114.864 2952.651)' fill='%23eaeaea'/%3E%3Cg transform='translate(1215.067 2952.651)'%3E%3Cpath d='M106.558,122.8l-9.274-19.257a1.309,1.309,0,0,0-2.409.017c-2.728,6.614-7.7,15.491-9.328,19.24-4.67,10.749.24,21.5,10.5,21.5,10.5,0,14.706-10.749,10.5-21.5M96.093,106.648c-2.816,6.727-6.968,13.718-8.425,17.071-2.113,4.864-1.976,9.531-.383,12.862a9.259,9.259,0,0,0,8.768,5.4,8.931,8.931,0,0,0,8.665-5.366c1.521-3.337,1.613-8.035-.3-12.949Z' transform='translate(-83.774 -102.753)' fill='%23ab192d' fill-rule='evenodd'/%3E%3Cpath d='M102.965,166.63c-2,4.691,3.834,12.231,1.164,12.012s-3.984-3.858-3.8-6.05a10.757,10.757,0,0,1,2.641-5.962' transform='translate(-94.836 -144.21)' fill='%23ab192d'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }
                &:nth-child(2) {
                    h3::after {
                       /* width: 38px;
                        height: 39px;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='38' height='39.091' viewBox='0 0 38 39.091'%3E%3Cg transform='translate(-207.898 -1176.572)'%3E%3Cpath d='M505.533,336.461a1.021,1.021,0,0,1,1.013.9l.006.119,0,19.962a1.018,1.018,0,0,1,.11.31l3.315,3.321a1.02,1.02,0,0,1-1.345,1.529l-.1-.085-3-3.008-3,3.008A1.02,1.02,0,0,1,501,361.171l.084-.1,3.315-3.321a.994.994,0,0,1,.111-.312v-19.96a1.02,1.02,0,0,1,1.019-1.021' transform='translate(-278.635 840.111)' fill='%2376bd22' fill-rule='evenodd'/%3E%3Cpath d='M136.772,10.853l-5.57,1.013-5.9,5.3-2.135,6.986.981,8.752,5.5,1.463,7.129-.907Z' transform='translate(85.383 1180.318)' fill='%23eaeaea'/%3E%3Cpath d='M467.493,391.025h-.778V411.1a2.065,2.065,0,0,0,2.063,2.066h7.706a2.065,2.065,0,0,0,2.063-2.066V402.1a11.062,11.062,0,0,0-11.053-11.072m-1.8-2.042h1.8A13.1,13.1,0,0,1,480.585,402.1V411.1a4.1,4.1,0,0,1-4.1,4.108h-7.706a4.105,4.105,0,0,1-4.1-4.108V390a1.02,1.02,0,0,1,1.019-1.021m-10.018,2.042h.779V411.1a2.065,2.065,0,0,1-2.063,2.066h-7.706a2.065,2.065,0,0,1-2.063-2.066V402.1a11.062,11.062,0,0,1,11.053-11.072m1.8-2.042h-1.8A13.1,13.1,0,0,0,442.585,402.1V411.1a4.105,4.105,0,0,0,4.1,4.108h7.706a4.1,4.1,0,0,0,4.1-4.108V390a1.02,1.02,0,0,0-1.019-1.021' transform='translate(-234.686 800.453)' fill='%2376bd22' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E%0A");*/
						width: 44px;
                        height: 26px;
                        background-image: url("data:image/svg+xml,%0A%3Csvg%20width%3D%2244%22%20height%3D%2226%22%20viewBox%3D%220%200%2044%2026%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M43.2226%2012.3358C38.4781%204.74453%2030.3175%200%2021.7774%200C13.2372%200%205.07664%204.74453%200.142336%2012.3358C-0.0474453%2012.7153%20-0.0474453%2013.0949%200.142336%2013.4745C4.88686%2021.0657%2013.2372%2026%2021.5876%2026C29.938%2026%2038.2883%2021.2555%2043.0328%2013.4745C43.2226%2013.0949%2043.2226%2012.7153%2043.0328%2012.3358H43.2226ZM21.7774%205.31387C23.1058%203.9854%2025.1934%203.9854%2026.3321%205.31387C27.6606%206.64234%2027.6606%208.72993%2026.3321%209.86861C25.0036%2011.1971%2022.9161%2011.1971%2021.7774%209.86861C20.4489%208.54015%2020.4489%206.45255%2021.7774%205.31387ZM21.7774%2023.7226C14.3759%2023.7226%206.97445%2019.5474%202.60949%2012.9051C5.64599%208.54015%209.82117%205.12409%2014.5657%203.41606C13.0474%205.12409%2012.2883%207.40146%2012.2883%209.67883C12.2883%2014.9927%2016.6533%2019.3577%2021.9672%2019.3577C27.281%2019.3577%2031.646%2014.9927%2031.646%209.67883C31.646%204.36496%2030.6971%205.12409%2029.3686%203.41606C34.1131%205.12409%2038.2883%208.35037%2041.3248%2012.9051C36.9599%2019.5474%2029.5584%2023.7226%2022.1569%2023.7226H21.7774Z%22%20fill%3D%22%2376BD22%22%2F%3E%0A%3C%2Fsvg%3E%0A");
                    }
                }
                &:nth-child(3) {
                    h3::after {
                        width: 20px;
                        height: 36px;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='35.85' viewBox='0 0 20 35.85'%3E%3Cg transform='translate(-480.623 -99.215)'%3E%3Cpath d='M88.857,4.975,90.132,2.3,87.537.792l-6.67.386.676,4.056L77.48,8.548l-1.287,3.567V33.588l2.637,2.556H92.255L94.823,32.2l-.475-20.082L92.255,8.108Z' transform='translate(405.314 98.92)' fill='%23eaeaea'/%3E%3Crect width='11.579' height='8.105' rx='4.053' transform='translate(484.834 122.367)' fill='%23cc28b0'/%3E%3Cpath d='M487.465,101.324h6.316l.094.009a.528.528,0,0,1,.19.962,2.111,2.111,0,0,0,.179,3.643,7.9,7.9,0,0,1,4.273,7.028v16.406l-.005.2a3.582,3.582,0,0,1-3.574,3.388h-8.632l-.2-.005a3.583,3.583,0,0,1-3.382-3.58V112.966l.006-.3A7.907,7.907,0,0,1,487,105.936a2.111,2.111,0,0,0,.176-3.643.527.527,0,0,1,.287-.969m8.947.527a2.634,2.634,0,0,0-2.632-2.636h-6.316a2.636,2.636,0,0,0-1.43,4.848,10.018,10.018,0,0,0-5.412,8.9v16.406a5.689,5.689,0,0,0,5.684,5.694h8.632a5.689,5.689,0,0,0,5.684-5.694V112.966a10.016,10.016,0,0,0-5.412-8.9,2.634,2.634,0,0,0,1.2-2.213' transform='translate(0 0)' fill='%23cc28b0' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }
                &:nth-child(4) {
                    h3::after {
                        width: 39px;
                        height: 39px;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39.238' height='39.469' viewBox='0 0 39.238 39.469'%3E%3Cg transform='translate(-652.277 -103.383)'%3E%3Cpath d='M698.99,104.091l-.053.134-5.425,16.235-10.668,3.179a1.234,1.234,0,0,0,.563,2.4l.139-.033,9.011-2.684-3,8.966a1.231,1.231,0,0,0,2.281.917l.055-.134,3.546-10.609,16.1-4.8a1.234,1.234,0,0,0-.563-2.4l-.139.033-14.44,4.3,4.876-14.592a1.232,1.232,0,0,0-2.215-1.041Z' transform='translate(-20.903 0)' fill='%23cc28b0' fill-rule='evenodd'/%3E%3Ccircle cx='3.486' cy='3.486' r='3.486' transform='translate(654.601 121.935)' fill='%23eaeaea'/%3E%3Cpath d='M657.818,162.125a3.084,3.084,0,1,1-3.078,3.083,3.081,3.081,0,0,1,3.078-3.083m0-2.467a5.551,5.551,0,0,0-.261,11.095l.261.006a5.551,5.551,0,0,0,.261-11.095Zm11.7,14.184a3.083,3.083,0,1,1-3.078,3.083,3.081,3.081,0,0,1,3.078-3.083m0-2.467a5.551,5.551,0,0,0-.261,11.095l.261.006a5.551,5.551,0,0,0,.261-11.095Z' transform='translate(0 -39.624)' fill='%23cc28b0' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }
                .has-background-grey-lighter h3 {
                    width: 100%;
                    padding-right: 1em;
                    &::after {
                        width: 51px;
                        height: 51px;
                        right: 0;
                        left: auto;
                        top: 0px;
                        transform: translateX(50%);
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='51' height='51' viewBox='0 0 51 51'%3E%3Cg transform='translate(-342.476 -353.349)'%3E%3Ccircle cx='25.5' cy='25.5' r='25.5' transform='translate(342.476 353.349)' fill='%23fff'/%3E%3Cpath d='M481.278,469.466a1.82,1.82,0,1,1-1.82-1.82,1.82,1.82,0,0,1,1.82,1.82' transform='translate(-94.801 -78.949)' fill='%23ab192d'/%3E%3Cpath d='M460.018,469.466a1.82,1.82,0,1,1-1.82-1.82,1.82,1.82,0,0,1,1.82,1.82' transform='translate(-79.001 -78.949)' fill='%23ab192d'/%3E%3Cpath d='M438.758,469.466a1.82,1.82,0,1,1-1.82-1.82,1.82,1.82,0,0,1,1.82,1.82' transform='translate(-63.201 -78.949)' fill='%23ab192d'/%3E%3Cpath d='M169.99,2.042V31.317l1.448.536h3.442l.939-2.273V2.042L173.995.659h-3.167Z' transform='translate(193.807 361.865)' fill='%23fff'/%3E%3Cpath d='M0,1.383V30.658l1.448.536H4.89l.939-2.273V1.383L4.005,0H.838Z' transform='translate(382.308 375.207) rotate(90)' fill='%23fff'/%3E%3Cpath d='M367.386,394.211h-1.82a2.733,2.733,0,0,1-2.73-2.73V381.454H352.809a2.733,2.733,0,0,1-2.73-2.73V376.9a2.733,2.733,0,0,1,2.73-2.73h10.028V364.147a2.733,2.733,0,0,1,2.73-2.73h1.82a2.733,2.733,0,0,1,2.73,2.73v10.027h10.027a2.733,2.733,0,0,1,2.73,2.73v1.82a2.733,2.733,0,0,1-2.73,2.73H370.116v10.028a2.733,2.733,0,0,1-2.73,2.73m-14.577-18.217a.911.911,0,0,0-.91.91v1.82a.911.911,0,0,0,.91.91h11.847v11.847a.911.911,0,0,0,.91.91h1.82a.911.911,0,0,0,.91-.91V379.634h11.847a.911.911,0,0,0,.91-.91V376.9a.911.911,0,0,0-.91-.91H368.3V364.147a.911.911,0,0,0-.91-.91h-1.82a.911.911,0,0,0-.91.91v11.847Z' fill='%23ab192d'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }

                // separació caixes
                &:nth-child(-n + 4) {
                    @include desktop {
                        margin-bottom: var(--space-2xl) !important;
                    }
                }
            }
        }
    }
    &--3 {
        h2 {
            top: -0.5em;
        }
    }
    &--4 {
        &__item {
            // backdrop-filter en pseudoelement per evitar problema en Chrome (https://stackoverflow.com/questions/60997948/backdrop-filter-not-working-for-nested-elements-in-chrome)
            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba($primary, 0.3);
                backdrop-filter: blur(14px);
                z-index: -1;
                border-radius: $radius-large;
            }
        }
        h3 {
            top: -0.85em;
        }
    }
}
