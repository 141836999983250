.wrapper {
    max-width: 77.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--space-l);
    padding-right: var(--space-l);
    &--narrow {
        max-width: 60rem;
    }
    &--narrower {
        max-width: 40rem;
    }
    &--desktop {
        @include touch {
            max-width: none;
            padding-left: 0;
            padding-right: 0;
        }
    }
    &--left {
        @include mobile {
            max-width: none;
            padding-left: 0;
            padding-right: 0;
        }
        @include tablet {
            max-width: none;
            margin-right: 0;
            padding-right: 0;
        }
        .home & {
            @include desktop {
                margin-top: var(--space-3xl);
            }
        }
        // Arregla margins i paddings per sliders
        .productes-i-serveis &,
        .recerca & {
            @include touch {
                .column:first-of-type {
                    padding-left: calc(var(--space-l) + 0.75rem);
                    padding-right: calc(var(--space-l) + 0.75rem);
                }
            }
        }
    }
}
