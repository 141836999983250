
#mapa.map{
	border-radius: 36px;;
}
.inscdonacionshospitals, .inscdonacions {
  .button--tertiary {
    font-weight: 500;
    // line-height: 38px;
    border: 0;
    color: $text;
    background-color: #eee;
    padding: vr(.6) vr(1.5);
    &:hover,
    &:focus {
      border: 0;
    }
  }
  label {
	    font-size: 1.2rem;
	    font-weight: 600;
	    margin: 0;
		small{
			font-size: 1rem;
		}
		&.error{
			font-size: .8rem;
   			 padding-left: 2.75em;
			 display: block;
		}
	}
	#dadespersonals input{
		padding: .375rem .75rem;
		height: 3.125rem;
	}
	.main-content fieldset {
		border: 0;
		padding: 0 0 1.5rem;
	}

  form.reserva, form.hospital {
    position: relative;
  }

  form.reserva .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    opacity: 0.5;
    background-color: #f4f4e3;
    top: 0;
    right: 0;
    img {
        position: absolute;
        //top: 50%;
    }
  }

    form.reserva {
        select {
            width: 100%!important;
            max-width: initial;
        }
        #hospital_day {
            margin-top: 1.3125rem;
            margin-bottom: 0;
        }
    }


  .button:disabled {
    opacity: 0.5;
  }

  .calendarRow {
    margin-top: 1.5rem;
  }

  #hospital_hour {
    font-size: 1em;
  }

  .calendari {
    margin-bottom: 1em;
    background-image: linear-gradient(to bottom, #ffffff, #eaeaea);
    width: 100%;
    font-size: rem-calc(16);
    text-align: center;
    border-bottom-left-radius:6px;
    border-bottom-right-radius:6px;
    th {
      text-align: center;
		padding: .5em;
	  border: none;

      &.dies{
        border-bottom: 1px #ccc solid;
      }
      abbr[title] {
         border:none;
        text-decoration: none;
        font-size: rem-calc(10);
        text-transform: capitalize;
      }
      span {
        font-size: rem-calc(16);
      }
      a {
        background-color: #fff;
        border: none;
      }
    }
    td {
      padding: rem-calc(8);
      color: #ccc;
	  padding: .5em;
      a {
        border: none;
        width:27px;
        margin:0 auto;
        display: block!important;

      }
      span.selected{
        color: #fff!important;
        background-color: $primary!important;
        margin:0 auto;
        display: block;
        border-radius: 50%!important;
        width:27px;
      }
    }
    .cal--selected {
      color: #fff!important;
      border-radius: 50%!important;
      display: block!important;
      background-color: $primary!important;
      width:27px;
      margin:0 auto;
      &:selection{
        background-color: $primary!important;
      }
    }
    .cal--obert {
      color: #000;
      background-color: transparent;

    }
    .arrow--next {
      float: right;
      margin-right: rem-calc(10);
    }
    .arrow--prev {
      float: left;
      margin-left: rem-calc(10);
    }
  }

  .wrapper-calendar.poblacio{
    .calendari--plasma{
      td{
        a.cal--obert{
           border-radius: 50%!important;
          display: block!important;
          border:solid 1px $primary!important;
          color:$primary;
          width:27px;
          margin:0 auto;
        }
      }
    }
  }


/*    input[type="search"],
    select,
    textarea {
      padding: vr(.25) vr(.5);
      height: rem-calc(50);
    }*/
    select#hospital, .select2, {
        font-size: .83333rem;
        line-height: 1.6;
        border-radius: 4px;
        border: solid 1px #d6d6d6;
        width: 100%;
        color: #4c4c4c;
        padding: .375rem .75rem;
        height: 3.125rem;
        background: white;
    }

    .select2-container--default .select2-selection--single {
        border: none;
        margin-top: .4em;
    }

    .select2-container--default .select2-results>.select2-results__options {
        color: #4c4c4c;
        font-size: .83333rem;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        top: 15px;
        right: 15px;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border: none;
        background: url(/media/img/lupa-cercador.svg);
        width: 25px;
        height: 25px;
        top: 0;
        left: 0;
    }

}
/*.top-header.top-header--simple{
  background-color: #fff;
  h1{
    color: $primary;
    text-transform: none;
    font-size: rem-calc(30);
    margin: 0;
    &.b{
      margin-bottom: 2.5rem;
    }
  }
  @include tablet {
    padding: 1rem 0;
    margin-bottom: 0;
  }

}*/
.inscdonacionshospitals{
  .grups{
    margin-bottom: rem-calc(35);

  }
  &.grups .fi{
      margin-top: 0;
    }
  h2{
    margin: 0;
  }


  .houdiniForm ul.tria-dia{
      text-align: left;
      margin:1em 0 2em;
  }
  .houdiniForm ul.tria-dia li{
      margin:1em 1em 0 0;
      max-width: 43%;
      display: inline-block;
      vertical-align: middle;
      @include tablet {
        max-width: 200px;
      }
  }

  .tria-dia a {
      border: 1px solid #000;
      border-radius: 4px;
      padding: 1rem;
      display: block;
      color: #000;
      opacity: .8;

	  text-decoration: none;
	  background-color: #fff;

  }
  .tria-dia  a.actiu {
        border-color: #1e8e3e;
      color: #1e8e3e;

      font-weight: 700;
      font-style: normal;
      position: relative;
      opacity: 1;
  }
  .tria-dia  a:after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 100px;
      top: -12px;
      position: absolute;
      right: -12px;
  }
  .tria-dia  a.actiu:after {
      background: url(/media/css/img/white-check.svg) no-repeat center center #1e8e3e;
      background-size: 50% auto;
  }

  #dades-reserva{
    /*background: #fff;
    padding:0;
    border-radius: 8px;
    padding: 3em 1em;
    -webkit-box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
    box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
    margin-top: -5rem;*/

    @include tablet {
      padding: 0 3em 0 4.5em;

    }
    form{
      margin: 0;
    }
     p.type-label{
        text-align: left;
        margin:0;
        font-size:1em;
        font-weight: 600;
      }
    #hora .opcions{

        label {
            float: left;
          &.selected{
            position: relative;
            &:after {
                  content: "";
                  display: block;
                  width: 24px;
                  height: 24px;
                  border-radius: 100px;
                  top: -12px;
                  position: absolute;
                  right: -12px;
                  background: url(/media/css/img/white-check.svg) no-repeat center center #1e8e3e;
                  background-size: 50% auto;
              }
			  &.complet{
				&:after {
					background: url(/media/css/img/playlist_add_white.svg) center center no-repeat #909090;
				}
			}
          }
        }
        legend {
            padding: 0;
            border: none;

            font-weight: 600;
            font-size: .875rem;
            letter-spacing: initial;
            &.title{
              font-weight: 300;
                  font-size: 1.2rem;
                  letter-spacing: .8px;
                   padding: 1.5rem 0 0.75rem;
            }
        }

    }



    .dades-hospital{
      .more{
          margin-bottom:2.5rem;
          @include tablet {
            margin-bottom:1.5rem;
          }
      }
      p{
        margin: 0;
        font-weight: 300;
        &.small{
          font-size: rem-calc(12);
        }
        &.seleccio{
			font-weight: bold;
			font-size:2.4em;

          margin-bottom:.5em;
          line-height: 1.2;
          &.b {
           font-size:1.5em;
		   margin-top: 0;
          }
        }
        &.campanya__titol{
          margin-bottom: 2.2em;
          margin-top: -4px;
          font-size: .8em;
          span{
            border-top: 3px solid #bc2b30;
            padding:.5em 0;
          }
        }
        &#marato{
          position: relative;
          display: inline-block;
          padding-right: 80px;
          &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            bottom: 12px;

          }
        }
        &.campanya{
          position: relative;
          display: inline-block;
          padding-right: 44px;
          &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            bottom: 12px;
          }
          &.c5:after{
            background-image: url(img/ico-bombers.svg);
            width: 34px;
            height: 44px;
          }
        }
        &.lloc,&.horari  {
          margin: 0 0 1em;

          font-weight: 300;
          font-style: normal;
        }
        &.horari{
          margin-bottom: 0.5em;
        }
      }
      a.button{
        font-size: rem-calc(12);
        text-transform: none;
        margin-top: 1rem;
        display: inline-block;
        max-width: 130px;
        padding:.3rem;
        border:solid 1px $primary;
        @include tablet {
          position: absolute;
          right: 0;
          bottom: 0;
          margin: 0;
        }
      }
      border-bottom:solid 1px #f7f7f7;
      padding-bottom: 1rem;
      margin-bottom:1rem;

    }
    [aria-hidden] {
      display: none;
    }
    fieldset{
      position: relative;
    }
    .pas{
      span{
		display: flex;
		align-items: center;
		justify-content: center;
        width: 30px;
        height: 30px;
        border:solid 1px $primary;
        border-radius: 50%;
        line-height: 30px;
        background-color: #fff;
        color: $primary;

        text-align: center;


      }
      &.completed{
          span{
            background-color: $primary;
            color: #fff;
          }
      }
      position: absolute;
      top: -45px;
      @include tablet {
        position: absolute;
        left: -50px;

        min-height: 100%;
        &:after{
          content: '';
          height: 100%;
          width: 1px;
          background-color: #efefef;
          display: block;
          position: absolute;
          left: 15px;
        }
        &#pas-dades:after{
          content: none;
        }
      }

    }
    legend{
		font-weight: 300;
		font-size: 1.5em;
       text-transform: none;
       text-align: left;
       color:$text;
       padding-top: 0;
       position: relative;
       padding-left: 1.5em;
	   margin-bottom: .5em;
      @include tablet {
        padding-left: 0;
      }
    }
    input,select{
      margin-top: .2rem!important;
    }
    #hospital_day{
      height: auto;

      border:none;
      outline: 0;
    }
    .info-dia{
        position: relative;
        padding:1em 2.6em 0;
        font-size: rem-calc(11);
        margin-top: -6px;
        margin-bottom: 8px;
        svg{
          position: absolute;
          left: 0;
          top:9px;
        }
      }
    .wrapper-calendar{
     // background-image: linear-gradient(to bottom,#fff,#eaeaea);
      margin:-1.8em 0 2em;
	  margin-left: -1em;
      padding: 1em .5em;
      border: 1px solid #ccc;
      box-shadow: 0 2px 5px rgba(0,0,0,0.2);
      position: relative;
      background: #fff;
	  border-radius: 36px;
      @include desktop{
        padding: 1em 1.5em;
      }
	  a{
		text-decoration: none;
	  }

    }
    table.calendari{
      background-image:none;
      @include tablet {
        width: 47%;
        margin-right:3%;
        float:left;

        &.last{
           margin-right:0;
            margin-left:3%;
        }
      }

      td{
		border: 0;
        a{
          width: 22px;
          height: 22px;
          line-height: 22px;
          font-size: rem-calc(14);
          @include desktop{
            width: 27px;
            height: 27px;
            line-height: 27px;
            font-size: rem-calc(16);
          }
        }
      }
    }
    #hora-select{
       margin-bottom: 4rem;
    }
    #hora{
      position: relative;
      .info{
        position: relative;
        padding:1em 2.6em 0;
        svg{
          position: absolute;
          left: 0;
          top:9px;
        }
      }
      .loading{
        left: 146px;
        top: -25px;
        height: auto;
      }
      .opcions{

        min-height: 65px;

        label{
          margin: 1em 4% 0 0;
          width: 100%;
          border: 1px solid #000;
          border-radius: 4px;
          padding: .75rem 1rem;
          float:left;
          min-width: 92px;
          min-height: 59px;
		  font-size: 1.3em;
		  background-color: #fff;

          &.selected{
            border-color:green;
            color:green;
          }
          &.complet{
            opacity: .5;
          }
          @include tablet {
            margin: .75em 1.5em 0 0;
			min-height: 20px;

          }
          input{
            margin: 0;
            margin-right:  .5em;
          }
        }
        .complet{
          legend{
            padding-bottom: 0.75rem;
          }
          label{
            float:none;
            border:none;
            padding:0;
            min-height: auto;
            min-width: auto;
            margin: 0;
            &.selected{
              color:#000;
              &:after{
                content: none;
              }
            }
          }
          .button{
                margin-top: 1.5rem!important;
          }
        }
      }
    }
    .alert-form {
      margin-top: 0;
      p{
        padding: .2rem 0 .2rem .75rem;
        @include tablet {
          padding-left: .25rem;
        }
      }
    }
    #dadespersonals {
        .loading{
            left: 50%;
            top: 50%;
            height: auto;
        }
        .info{
            position: relative;
            //padding:1em 2.6em 0;
            svg{
              position: absolute;
              left: 0;
              top:9px;
            }
        }
    }


    .detall{
      //background-color: #439898;
	  background-color: #fff;
      padding: 1em;
      margin: 2em 0;
      border-radius:4px;
      display: flex;
      flex-wrap: wrap;
      #detall-hospital{
        display: block;
      }
      h2{
        font-size: rem-calc(12);
        margin: 0;
        flex-grow: 1;
         width: 30%;
         padding-right: 1em;
         border-right: solid 1px #000;
         @include tablet {
          font-size: rem-calc(16);
          width: 20%;
         }

      }
      div{
        font-size: rem-calc(12);
        margin: 0;
        flex-grow: 1;
        width: 70%;
        padding-left:1em;
        @include tablet {
          padding-left:2em;
          width: 80%;
         }
        p{
          margin: 0;
        }
        a{
          font-size: rem-calc(10);
        }
      }

    }

  }
  .nota{
      margin-top: 2rem;
    }
  .menu-fixed,.top{
    display: none!important;
  }
  form.reserva .loading{
    background: #fff;
  }

}
.calendar-component__triangle{
    position: absolute;
    left:50px;
    top: -17px;
    width: 35px;
    height: 18px;
    transition: left linear 0.3s;
    -o-transition: left linear 0.3s;
    -moz-transition: left linear 0.3s;
    -webkit-transition: left linear 0.3s;
    &:before {
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      content: " ";
      height: 0;
      position: absolute;
      left: calc(50% - 12px);
      right: auto;
      width: 0;
      top: 3px;
      border-bottom: 13px solid #ccc;
    }
    &:after {
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      content: " ";
      height: 0;
      position: absolute;
      left: calc(50% - 12px);
      right: auto;
      width: 0;
      top: 4px;
      border-bottom: 13px solid #fff;
    }
}

.diaDona{
  background-color: #783fa0;
  color: #FFF !important;
}

.diaDona a{
  color: #FFF !important;
}

.ocupacio{
    font-size:.6em;
    display: block;
	margin-top: 1em;
	@include tablet {
		display: inline-block;
		margin-top: .5em;
		float: right;
	}
}
.es .ocupacio{
    font-size:.615em;
}
.ocupacio span{
    display:inline-block;
    border-radius:50%;
    background-color:green;
    width: 10px;
    height: 10px;
    margin-right: 5px;
}
.ocupacio span.mitja{
    background-color:orange;
}
.ocupacio span.complet{
    background-color:red;
}
#compliment_lopd{
    margin-right:.3em;
}

.inscdonacionshospitals #dades-reserva #hora .info.avis,
.inscdonacionshospitals #dadespersonals .info.avis{
    font-size: 1.4em;
    color: #bc2b30;
    padding-top: 0;
    padding-left:0;
    font-weight: bold;
}
.inscdonacionshospitals #dades-reserva .complet{
  fieldset{
    legend{

      font-weight: 700;
      padding-bottom: 0.75rem;
      font-style: normal;
      font-size: .85rem;
      letter-spacing: normal;
      padding-left:0;
      padding-top:2em;
      @include tablet {
        padding-top:0;
      }
    }
    #compliment_lopd{
      margin-bottom: 0;
    }
     #legalhover{
      margin: 1rem 0 0;
     }
    #legalhover p{
      font-size: .7rem;
      font-weight: 400;
      padding-left: 0;

    }
    button#rebre-avis{
      margin-top: 1.5rem;
    }
    #compliment_lopd-error{
      margin-top: 1em;
    }
  }
}
.inscdonacionshospitals #dades-reserva  fieldset.options legend{
  font-size: .875rem;
  font-weight: 600;
  margin: 0;
  padding-left: 0;
  font-family: open_sansregular,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
}
.inscdonacionshospitals .grups{
    margin-bottom: 0;
}
fieldset.options label{
    display:inline;font-weight:initial
}
.pas svg{
    fill:#bc2b30;
}
.pas.completed svg{
    fill:#fff;
}
.grups .buscar-form p.seleccio{
    max-width: 700px;
}
.a-progress-bar {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: .125rem;
    background-color: #cceede;
    color: #02a95c;
    display: block;
    height: .25rem;
    width: 100%;
}
.progress-bar {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: .125rem;
   background-color:#f4f4e3;
   color:#bc2b30;
    display:block;
    height:.25rem;
    width: 100%;

}
.inscdonacionshospitals .progress-meter-heading{
    font-size: 1.3em;
    margin-bottom: .5em;
    line-height: 1;

    font-weight: 300;
}
.inscdonacionshospitals .progress-meter-heading b{
    color: #bc2b30;
}
.inscdonacionshospitals .progress-meter-heading span{
    font-size: .675em;
    }
.inscdonacionshospitals .progress-meter-heading span.box-franges{
    font-size: 1em;
    box-shadow: 6px 6px 0px #f4f4e3;
    transform:translate(-3px, -3px);
    border:solid 1px #f4f4e3;
    padding:2em;
    display: block;
}
.inscdonacionshospitals #dades-reserva p.progress-meter-text{
    font-size: .8em;
    text-align: right;
    margin-top: .5em;
}
.progress-bar::-webkit-progress-bar {
    border-radius: .125rem
}

.progress-bar::-webkit-progress-value {
    border-radius: .125rem;
    -webkit-transition: width 5s ease;
    transition: width width 5s ease;

}

.progress-bar::-moz-progress-bar {
    border-radius: .125rem
}

.progress-bar::-ms-fill {
    border-radius: .125rem
}

.progress-bar--red::-webkit-progress-bar {
    background-color: #f4f4e3
}

.progress-bar--red::-webkit-progress-value {
    background-color: #bc2b30
}

.progress-bar--red::-moz-progress-bar {
    background-color: #bc2b30
}

.progress-bar--red::-ms-fill {
    background-color: #bc2b30
}

#hospital_day-error{
    position: absolute;
    left: 53px;
    top: 8px;
    line-height: 1;
}
#legal-error{
    margin-top: 5px;
}
#dades-reserva #hora .opcions label#hour-error,#dades-reserva #hora .opcions label#franja-error{
    padding: .30em 1em;
    width: 96%;
    display: block;
    margin: 0;
    min-height: auto;
    font-size: 1em;
    float: none;
    border-radius:0;
    border: solid 1px #bc2b30;
    background-color:#bc2b30;
    color: #fff;
    font-weight: 400;
    @include tablet {
      width: auto;
      position: absolute;
      top: -.3em;
      left: 80px;
      &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 100%;
          margin-top: -7px;
          border-width: 7px;
          border-style: solid;
          border-color: transparent #bc2b30 transparent transparent;
      }
    }

}
@include tablet {
  #dades-reserva #hora .opcions label#franja-error{
    left: 140px;
  }
}
#compliment_lopd.error{
      outline: 1px solid red;
}
.fitxa  .mfp-bg {
    opacity: .1;
}

.white-popup {
  border: 1px solid #bc2b30;
  border-radius: 8px;
  position: relative;
  background: #fff;
  padding: 4.5em 2em 2em;
  width: auto;
  max-width: 250px;
  font-size: .9em;
  margin: 20px auto;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgba(60,64,67,.05), 0 4px 8px 3px rgba(60,64,67,.15);
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 1em;
    left: 50%;
    margin-left:-17px;

  }
}
select#acompanyants{
  background: #fff;
}
.inscdonacionshospitals .main-content p.link.b{
    margin-top: 2em;
}
.inscdonacionshospitals .main-content  button,.inscdonacionshospitals .main-content  .button {
    width: auto;
}
@media only screen and (min-width: 46.3125em){

    #hospital_day-error{
           top: -13px;
    left: 250px;
    }
    .white-popup {
      max-width: 400px;
    }
    .inscdonacionshospitals .main-content p.link.b{
        margin-top: 0;
    }
}
.share-list a.bt-wt{
      border: 1px solid #bc2b30;
      margin: 0 0 1.5em 0;
      width: 100%;

}
.share-list a.bt-tg{
    border: 1px solid #bc2b30;
    margin-left:0;
    width: 100%;
  img{
    padding: 4px;
  }
}

.recordatori{
    background: #fff;
    padding: 3em 2em;
    margin: 0 0 2em;
    border-radius: 36px;
	hr{
		margin:1em 0;
		color: #efefef;
	}
    /*box-shadow: 0 2px 10px rgba(0,0,0,.10);*/

}
.box-actions{
	background: #fff;
    border-radius: 12px;
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, .25), 0 8px 16px -8px rgba(0, 0, 0, .3), 0 -6px 16px -6px rgba(0, 0, 0, .025);
	input{
		padding: .375rem .75rem;
		height: 3.125rem;
		max-width: 450px;
	}
	select{
		padding: .375rem .75rem;
		height: 3.125rem;
	}
	.select:not(.is-multiple):not(.is-loading)::after {
		margin-top: -.1375em;
	}
}
.privacitat-checkbox{
  text-align: left;
  margin:1em 0;
  #compliment_lopd{
        margin-top: 0.2rem!important;
  }
}
.inscdonacionsplasma{
  .inscdonacionshospitals #dades-reserva #hora .opcions label.complet.selected {
      border-color: #909090;

  }
  .inscdonacionshospitals #dades-reserva #hora .opcions label.complet.selected:after {
      background: url(img/playlist_add_white.svg) center center no-repeat #909090;
  }
  #dades-reserva #hora .opcions label.complet{
  opacity: 1;color: #909090;
  }
  .nota p{
      font-size: .7em;
      line-height: 1.25;
      padding-bottom:2em;
  }
  .inscdonacionshospitals .houdiniForm .wrapper-list{
    margin: -2.8em 0 2em;
    .tria-dia{
      margin-top: 0;
      li{
        background:#fff;
      }
    }
  }
  /*@media only screen and (min-width: 46.3125em){
     #dades-reserva #hora .opcions label{
          width: 152px;
          min-width: 152px;
          max-width: 152px;
      }
  }*/

}


.afegir-agenda{
	position: relative;
}
.boto-calendari{
    display: inline-block;
    background-color: #fff;
    border: 1px solid transparent;
    padding: .75em 1.5em .75em 2.25em;
    box-shadow: 0 0 0 0.5px rgba(50,50,93,.17), 0 2px 5px 0 rgba(50,50,93,.1), 0 1px 1.5px 0 rgba(0,0,0,.07), 0 1px 2px 0 rgba(0,0,0,.08), 0 0 0 0 transparent !important;
    border-radius: 3px;
    margin:1em 0 2.5em;
    font-size: .9em;
    position: relative;
    color: #414141;
    &:hover{
    	background-color: #fafafa;
    }
    &:active{
    border-width: 2px 1px 0px 1px;
    }
    svg{
    	position: absolute;
    	left:5px;
    	top: 50%;
    	margin-top: -12px;
    	fill:#414141;
    }
}


.calendari-opcions{
	width: 230px;
    position: absolute;
    padding: 6px 0px 0px 0px;
    margin-left: -1px;
    display: none;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -webkit-box-shadow: rgba(0,0,0,0.4) 0px 10px 26px;
    -moz-box-shadow: rgba(0,0,0,0.4) 0px 10px 26px;
    box-shadow: rgba(0,0,0,0.4) 0px 10px 26px;
    transform: scale(.98,.98) translate(-50%,-20%);
    opacity: 0.5;
    z-index: -1;
    transition: transform .15s ease;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    background: #fff;
    left: 50%;
    top: 0;
    transform: translate(-50%,-50%);
    &.selected{
    	opacity: 1;
    	transform: scale(1,1) translate(-50%,-35%);
    	z-index: 10;
    	display: block;
    }
    #tancar-calendari{
    	position: absolute;
	    right: -46px;
	    top: 0;
	    padding: 10px;
	    background-color: #efefef;
	    margin: 0;
	    line-height: 1;
    	img{
    		margin:0;
    	}
    }
    ul{
	    @include undolist;
	    li{
	    	margin:.25em 0;
	    	text-align: left;
	    	a{
	    		-webkit-transition: background-color .3s;
	    		transition: background-color .3s;
	    		display: block;
	    		color:#414141;
	    		text-decoration: none;
	    		border: none;
	    		padding: .6em 1em .6em 55px;
	    		background: url(img/ical.svg) 20px 48% no-repeat;
	    		background-size: 24px 24px;
	    		&.apple{
	    			background: url(img/ico-apple.svg) 20px 40% no-repeat;
	    			background-size: 22px 100%;
	    		}
	    		&.google{
	    			background: url(img/ico-google.svg) 20px 40% no-repeat;
	    			background-size: 22px 100%;
	    		}
	    		&.outlook{
	    			background: url(img/ico-outlook.svg) 20px 40% no-repeat;
	    			background-size: 22px 100%;
	    		}
	    		&.outlookcom{
	    			background: url(img/ico-outlookcom.svg) 20px 40% no-repeat;
	    			background-size: 22px 100%;
	    		}
	    		&:hover{
	    			background-color: #efefef;
	    		}
	    	}
	    }
	}
}
.border-primary{
	border: solid 1px #ab192d;
}
.alert-2 {
    background-color: #f4f4e3;
    padding: 1.5rem;
    /*margin-bottom: 2.25rem;*/
	text-align: center;

}

/* calendari**/

 .cal{
	margin-bottom: 4em!important;
	.cal__dia{

		font-size: 2em;
		padding-top:var(--space-xs);
		padding-left:var(--space-xs);
		display: inline-block;

	}
	caption {

		font-size: 1.728rem;
		line-height: 1.2;

		font-weight: 700;

		padding: 0 0 1.5rem;
		position: relative;
	}
	th{
		padding-left:var(--space-xs);
	}
	td{
		border: none;
		vertical-align: top;
		padding: 0;
		width: 14.28%;
		li{
			margin-bottom:  var(--space-xs);
			padding-left:var(--space-xs);
		}
		a{
			text-decoration: none;
			display: inline-block;
			&:hover{
				text-decoration: underline;
				color:#000;

			}
		}
		small{
			display: block;
			line-height: 1;
		}

	}


	td:has(>span){
		border: solid  rgba(0, 0, 0, .65) 1px!important;
		padding:0  var(--space-xs) var(--space-xs) 0;
		height: 150px;
	}


 }



/*snazzy*/
.si-float-wrapper {
	position: absolute;
	width: 100%; }
	.si-float-wrapper,
	.si-float-wrapper * {
	  box-sizing: border-box; }

  [class*='si-wrapper'] {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	font-size: 18px;
	cursor: default; }
  .si-float-wrapper {
  .si-wrapper-top {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	margin-top: -40px;
	margin-left: 0px;
	-webkit-transform: translate(-50%, -100%);
			transform: translate(-50%, -100%); }

  .si-wrapper-bottom {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
		-ms-flex-direction: column-reverse;
			flex-direction: column-reverse;
	margin-top: 0px;
	margin-left: 0px;
	-webkit-transform: translate(-50%, 0);
			transform: translate(-50%, 0); }

  .si-wrapper-left {
	margin-top: -10px;
	margin-left: -11px;
	-webkit-transform: translate(-100%, -50%);
			transform: translate(-100%, -50%); }

  .si-wrapper-right {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
		-ms-flex-direction: row-reverse;
			flex-direction: row-reverse;
	margin-top: -10px;
	margin-left: 11px;
	-webkit-transform: translate(0, -50%);
			transform: translate(0, -50%); }

  [class*='si-shadow-wrapper'] {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0.4;
	z-index: 1; }

  .si-shadow-wrapper-top,
  .si-shadow-wrapper-bottom {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column; }

  .si-shadow-pointer-bottom,
  .si-shadow-pointer-right {
	-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
			order: -1; }

  .si-shadow-frame {
	box-shadow: 5px 5px 0 0 #000; }

  .si-frame {
	position: relative;
	-webkit-box-flex: 1;
		-ms-flex: 1 1 auto;
			flex: 1 1 auto;
	border-radius: 6px;
	overflow: hidden;
	z-index: 2; }

  .si-content-wrapper {
	width: 100%;
	max-width: 100%;
	max-height: 100%;
	padding: 0px;
	background-color: #fff; }
	.si-has-border .si-content-wrapper {
	  border: 1px solid #bc2b30;
	  }

  .si-content {
	//overflow: auto;
	overflow: hidden;
   }

  .si-close-button {
  //	display: none;

	position: absolute;
	top: 0;
	right: 0;
	border: 0;
	outline: none;
	background-color: transparent;
	color: inherit;
	font-family: Arial, Baskerville, monospace;
	font-size: 2em;
	cursor: pointer;
	opacity: 0.7;
	z-index: 2;
	-webkit-appearance: none;
	   -moz-appearance: none;
			appearance: none; }
	.si-close-button:hover, .si-close-button:focus {
	  opacity: 1;

  }

  [class*='si-pointer-border'] {
	position: absolute;
	border: 15px solid transparent;
	z-index: 3; }

  [class*='si-pointer-bg'] {
	position: relative;
	border: 15px solid transparent;
	z-index: 4; }
	.si-has-border [class*='si-pointer-bg'] {
	  border-width: 12px; }

  .si-pointer-border-top,
  .si-pointer-border-bottom {
	left: 50%;
	-webkit-transform: translate(-50%, 0);
			transform: translate(-50%, 0); }

  .si-pointer-border-left,
  .si-pointer-border-right {
	top: 50%;
	-webkit-transform: translate(0, -50%);
			transform: translate(0, -50%); }

  .si-pointer-top {
	border-bottom: 0;
	display:none !important;
	}

  .si-pointer-border-top {
	bottom: 0;
	border-top-color: #cbcbcb; }

  .si-pointer-bg-top {
	border-top-color: #fff; }
	.si-has-border .si-pointer-bg-top {
	  top: -0px;
	  margin-bottom: 3px; }

  .si-pointer-bottom {
	border-top: 0; }

  .si-pointer-border-bottom {
	top: 0;
	border-bottom-color: #cbcbcb; }

  .si-pointer-bg-bottom {
	border-bottom-color: #fff; }
	.si-has-border .si-pointer-bg-bottom {
	  bottom: -0px;
	  margin-top: 3px; }

  .si-pointer-left {
	border-right: 0; }

  .si-pointer-border-left {
	right: 0;
	border-left-color: #cbcbcb; }

  .si-pointer-bg-left {
	border-left-color: #fff; }
	.si-has-border .si-pointer-bg-left {
	  left: -0px;
	  margin-right: 3px; }

  .si-pointer-right {
	border-left: 0; }

  .si-pointer-border-right {
	left: 0;
	border-right-color: #cbcbcb; }

  .si-pointer-bg-right {
	border-right-color: #fff; }
	.si-has-border .si-pointer-bg-right {
	  right: -0px;
	  margin-left: 3px; }


  .si-wrapper-top {
	max-width: 300px; }
	@include tablet {
	  .si-wrapper-top {
		max-width: 400px; } }
	@include desktop {
	  .si-wrapper-top {
		  min-width: 595px;
		max-width: 600px; } }

  @media (max-width: 768px) {
	.si-frame {
	  padding: 0px; } }



  .si-close-button {
	width: 40px;
	height: 40px; }

	.donacio {
	  padding: 15px 30px 18px;
	  hr {
		  margin: 1rem 0;
	  }
	  &__title {
		  font-size: rem-calc(30);
		  font-weight: 300;
		  line-height: 1;
		  margin-bottom: 0;
		  margin-top: 1rem;

	  }
	  &__data {
		  font-size: rem-calc(16);
		  margin-bottom: rem-calc(18);
	  }
	  &__time {
		  font-size: rem-calc(12);
		  margin-bottom: rem-calc(16);
		  position:relative;
		  display: flex;
		  text-align: left;
		  gap: .25em;
		  &:before {
			//@extend .ico-horari-fitxa-popup;
			position: absolute;
			left: 0;
			top: -2px;
		  }

	  }
	  &__lloc {
		  font-size: rem-calc(12);
		  margin-bottom: rem-calc(16);
		  position:relative;
			display: flex;
		  text-align: left;
		  gap: .25em;
		  &:before {

			//@extend .ico-lloc-fitxa-popup;
			position: absolute;
			left: 0;
			top: 0;
		  }
	  }
	  &.marato {
		  padding-top: 45px;
	  }


	}

	.repeat .donacio{
		  padding-top: 0;
		  margin-top: rem-calc(-16);
		  &__title {
			  display: none;
		  }
	}

		@include desktop {
		  .donacio {
			  padding: 27px 70px 18px;
			  &__title {
				  margin-top: 1.5rem;
			  }

		  }
	  }

  }

  .si-wrapper-top.donarsang {
	  position: relative;
	  margin-top: -85px;
	  /*&:after {
		  content: ' ';
		  @extend .pin-mapa-itinerant;
		  position: absolute;
		  bottom: -40px;
		  left: calc(50%-14px);
		  display:block;
		  z-index: 10;
	  }*/
	  &:after {
		  content: ' ';
		  position: absolute;
		  bottom: -19.5px;

		  display:block;
		  z-index: 10;
		 position: absolute;
		  opacity: .35;
		  border-right: 10px solid transparent;
		  border-left: 10px solid transparent;
		  border-top: 15px solid #000;
	  }
	  &.fixe {
		  &:after {
		  //@extend .pin-mapa-fixe;
		  }

		  .donacio__title {
			  font-size: rem-calc(25);
		  }
	  }
  }


 .inscdonacionshospitals.seccio-plasma #dades-reserva .pas.completed span, .inscdonacionsplasma.seccio-plasma #dades-reserva .pas.completed span {
    background-color: #FDC300;
    color: #000;
}
.inscdonacionshospitals.seccio-plasma #dades-reserva .pas span, .inscdonacionsplasma.seccio-plasma #dades-reserva .pas span {
    border: 1px solid #FDC300;
    color: #FDC300;
}
.inscdonacionshospitals.seccio-plasma #dades-reserva .pas svg, .inscdonacionsplasma.seccio-plasma #dades-reserva .pas svg {
    fill: #FDC300;
}
.inscdonacionshospitals.seccio-plasma #dades-reserva .pas.completed svg, .inscdonacionsplasma.seccio-plasma #dades-reserva .pas.completed svg {
    fill: #000;
}


.inscdonacionshospitals.seccio-plasma .calendari .cal--selected {
	color: #000!important;
	background-color: #FDC300!important;

	&:selection{
	  background-color: #FDC300!important;
	}
  }