.highlighted-link a {
    display: inline-flex;
    align-items: center;
    padding: 0.6rem 1rem;
    border: 2px solid $primary;
    text-decoration: none;
    font-weight: 500;
    min-width: 40ch;
    position: relative;
    font-size: var(--step--1);
    &:hover,
    &:focus {
        background-color: $primary;
        border-color: #fff;
    }
    &::before {
        content: "";
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        background-image: url("../img/sprites-svg/link.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        margin-right: var(--space-xs);
    }
    &[href$=".pdf"],
    &[href^="/media"] {
        &::before {
            background-image: url("../img/sprites-svg/file-arrow-down.svg");
        }
    }
}
