.grafic-gr {
    height: auto;
    &__mask {
        opacity: 0;
        // transition: opacity 0.125s $easing;
        // &:hover {
        //     opacity: 1;
        // }
    }
    // &__circles {
    //     transition: opacity 0.125s $easing;
    // }
    &__text {
        cursor: pointer;
        &:hover {
            opacity: 1 !important;
            text {
                text-decoration: underline;
            }
        }
    }
}
