.link-dest {
    color: $primary;
    // font-family: helvetica_neuebold, $family-primary;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    gap: var(--space-2xs);
    font-size: var(--step--1);
    text-decoration: none;
    &::after {
        content: "";
        width: 11px;
        height: 11px;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23AB192D' d='M11 5.5 0 11V0z' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
    &:hover {
        font-weight: 700;
        font-style: italic;
        // font-family: helvetica_neuebold_italic, $family-primary;
    }
}
