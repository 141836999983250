.desplegable-destacat-b {
    summary span.summary-icon::after {
        width: 33px;
        height: 33px;
        background-image: url("data:image/svg+xml,%3Csvg width='33' height='33' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23FFF' fill-rule='nonzero' cx='16.5' cy='16.5' r='16.5'/%3E%3Cpath stroke='%23AB192D' stroke-width='2' stroke-linecap='round' d='M23.897 16.766H9.103M16.5 9.369v14.794'/%3E%3C/g%3E%3C/svg%3E");
    }
    .button {
        transform: translateY(30%);
    }
}
