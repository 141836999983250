.search-form {
    input,
    button {
        background: none;
        border: solid $primary;
        border-width: 0 0 1px;
        &:hover {
            border-color: $primary;
        }
    }
    input {
        &::placeholder {
            display: inline-block;
            color: #fff;
        }
    }
    button {
        height: $control-height;
        font-size: 1rem;
        border-radius: 0;
        img {
            filter: brightness(100);
        }
        &:hover,
        &:focus {
            img {
                filter: none;
                transform: rotate(-15deg);
            }
        }
    }
    &--internal {
        input {
            &::placeholder {
                color: $text;
            }
        }
        button {
            img {
                filter: brightness(0);
                opacity: 0.75;
            }
            &:hover,
            &:focus {
                img {
                    opacity: 1;
                }
            }
        }
    }
    &--solid {
        input,
        button {
            background: rgba(255, 255, 255, 0.82);
            border: none;
        }
        input {
            border-top-left-radius: $radius-rounded !important;
            border-bottom-left-radius: $radius-rounded !important;
        }
        button {
            border-top-right-radius: $radius-rounded;
            border-bottom-right-radius: $radius-rounded;
            height: 100%;
        }
        @include mobile {
            .control {
                height: auto;
            }
        }
    }
}
