.desplegable-foto {
    border-radius: $radius-large;
    overflow: hidden;
    summary + div {
        a {
            text-decoration: none;
            color: #fff;
        }
    }
    &[open] {
        summary {
            svg {
                transform: rotate(0.125turn);
            }
            img {
                display: none !important;
            }
        }
		details{
			summary {
				svg {
					transform: none;
				}

			}
			&[open] {
				summary {
					svg {
						transform: rotate(90deg);
					}
				}
				details{
					summary {
						svg {
							transform: none;
						}

					}
					&[open] {
						summary {
							svg {
								transform: rotate(90deg);
							}
						}
					}
				}
			}
		}
    }
}
