.main-content {
    padding-top: 8rem;
    // .has-sub-nav & {
    //     padding-top: 10rem;
    //     @include mobile {
    //         padding-top: 13rem;
    //     }
    // }
    .a-privada & {
        @include desktop {
            padding-right: 290px;
        }
        @media screen and (min-width: 1760px) {
            padding-right: 0;
        }
    }
	@include desktop {
		min-height: 600px;
	}
	@include widescreen {
		min-height: 800px;
	}
}
.no-js .has-sub-nav .main-content {
    padding-top: 10rem;
    @include mobile {
        padding-top: 15rem;
    }
}


//classes per notícies importades blog
.alignleft {
    margin-bottom: 1.5em;
    display: block;
}
.alignright, img.right {
    margin-bottom: 1.5em;
    display: block;
}


.aligncenter {
    margin: 2rem auto;
    display: block;
	clear: both;
}
@media (min-width: 768px) {
    .alignleft {
        float: left;
        margin: 0 1.5em 2.5em 0!important;
    }
	.alignright, img.right {
        float: right;
        margin: 0 0 2.5em 1.5em!important;
    }
}
.moltes-gracies{
	p img{
		display: block;

	}
	h1{
		position: relative;
		padding-left: 65px;
		&::before {
			content: "";
			display: block;
			width: 50px;
			height: 50px;
			background-image: url(img/cometes-black.svg);
			background-repeat: no-repeat;
			position: absolute;
			left: 0;
			top: -20px;
		}
	}
	.cap p{
		padding-left: 65px;
	}
	blockquote {
		padding-left: 45px;
		position: relative;
		display: flex;
	}
	blockquote::before {
		content: '“';
		position: absolute;
		left: -10px;
		top: 0;
		font-size: 116px;
		color: #d00233;
		padding-right: 20px;
	}
	blockquote span {
		display: block;
		font-size: 32px;
		line-height: 34px;
		font-style: italic;
		text-transform: initial;
		color: #000000 !important;
		margin-top: 40px;
		margin-bottom: 40px;
	}

	.wp-caption .wp-caption-text {
	    line-height: 1.3em;
	    font-size: .8rem;
	    color: #999999;
	}
}