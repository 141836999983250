.motius-list {
    list-style-position: inside !important;
    ::marker {
        content: counter(list-item);
        font-weight: bold;
        // font-family: helvetica_neuebold, $family-primary;
        color: $text;
        font-size: var(--fluid-90-143);
        line-height: 1;
    }
    div {
        position: absolute;
        left: 0;
        top: 0;
        padding-left: var(--space-2xl-3xl);
        @include tablet {
            width: 40ch;
        }
    }
    img {
        position: absolute;
        @include mobile {
            transform: scale(0.6);
			top: -1rem !important;
        	left: 1.25rem !important;
            // outline: 1px solid yellow;
        }
        @include tablet-only {
            transform: scale(0.9);
            // outline: 1px solid blue;
        }
        // @include desktop {
        //     outline: 1px solid fuchsia;
        // }
    }
	svg {
        position: absolute;
	}
    &::before {
        @include tablet {
            content: "";
            background-repeat: no-repeat;
            display: block;
            position: absolute;
            transform: translateY(-50%);
            // outline: 2px solid yellowgreen;
        }
    }
    li {
        position: relative;
    }
    // Pàgina Donacions
    &--1 {
        &::before {
            @include tablet {
                width: 1400px;
                height: 590px;

                // background-image: url("img/6-motius.jpg");
                left: -1170px;
                top: 60%;
                border-top-right-radius: $radius-rounded;
                border-bottom-right-radius: $radius-rounded;
            }
            @include desktop {
                height: 678px;
                left: -430px;
				width: 1000px;
                // outline: 2px solid pink;
            }
            @include widescreen {
                left: -460px;
				width: 1100px;
                // outline: 2px solid blueviolet;
            }

			@include fullhd {
                left: -520px;
				width: 1100px;
            }
			@media screen and (min-width: 1600px) {
                left: -620px;
				width: 1200px;
            }
			@media screen and (min-width: 1925px) {
                left: -820px;
				width: 1400px;
            }
        }
        li {
            &:nth-child(1) {
                @include tablet {
                    margin-left: 10%;
                }
                @include desktop {
                    margin-left: 32%;
                }
                img,svg {
                    @include mobile {
                        left: 1rem;
                        top: -0.5rem;
                    }
                    @include tablet {
                        left: 2rem;
                        top: -0.5rem;
                    }
                    @include desktop {
                        left: 2.25rem;
                        top: -0.5rem;
                    }
                }
            }
            &:nth-child(2) {
                @include tablet {
                    margin-left: 20%;
                }
                @include desktop {
                    margin-left: 55%;
                }
                img,svg {
                    @include mobile {
                        left: 1rem;
                        top: -1rem;
                    }
                    @include tablet {
                        left: 2rem;
                        top: -1rem;
                    }
                    @include tablet {
                        left: 2.25rem;
                        top: -0.75rem;
                    }
                }
            }
            &:nth-child(3) {
                @include tablet {
                    margin-left: 30%;
                }
                @include desktop {
                    margin-left: 66%;
                }
                img,svg {
                    @include mobile {
                        left: 2rem;
                        top: -1.25rem;
                    }
                    @include tablet {
                        left: 2rem;
                        top: -1.25rem;
                    }
                    @include desktop {
                        left: 3rem;
                        top: -1.25rem;
                    }
                }
            }
            &:nth-child(4) {
                @include tablet {
                    margin-left: 40%;
                }
                @include desktop {
                    margin-left: 71%;
                }
                img,svg {
                    @include mobile {
                        left: 1.75rem;
                        top: -1.25rem;
                    }
                    @include tablet {
                        left: 2.25rem;
                        top: -1rem;
                    }
                    @include desktop {
                        left: 3rem;
                        top: -0.75rem;
                    }
                }
            }
            &:nth-child(5) {
                @include tablet {
                    margin-left: 30%;
                }
                @include desktop {
                    margin-left: 68%;
                }
                img,svg {
                    @include mobile {
                        left: 1rem;
                        top: -1rem;
                    }
                    @include tablet {
                        left: 2rem;
                        top: -1rem;
                    }
                    @include desktop {
                        left: 2.5rem;
                        top: -0.75rem;
                    }
                }
            }
            &:nth-child(6) {
                @include tablet {
                    margin-left: 20%;
                }
                @include desktop {
                    margin-left: 54%;
                }
                img,svg {
                    @include mobile {
                        left: 1.5rem;
                        top: -1rem;
                    }
                    @include tablet {
                        left: 2.125rem;
                        top: -1rem;
                    }
                    @include desktop {
                        left: 2.5rem;
                        top: -1rem;
                    }
                }
            }
        }
    }
    // Pàgina Donacions/Sang
    &--2 {
        &::before {
            @include tablet {
                width: 1400px;
                height: 580px;
                // background-image: url(https://picsum.photos/1400/580);
                background: $dark url("img/donacions-sang-foto-1.jpg") no-repeat;
                right: -1050px;
                top: 35%;
                border-top-left-radius: $radius-rounded;
                border-bottom-left-radius: $radius-rounded;
                // outline: 1px solid red;
            }
            @include desktop {
                //right: -960px;
				right: -680px;
        		width: 1100px;
                // outline: 2px solid pink;
            }
            @include widescreen {
                top: 43%;
        		width: 1100px;
				right: -570px;
                // outline: 2px solid blueviolet;
            }
			@include fullhd {
				right: -960px;
				width: 1400px;
			 }
            // @include fullhd {
            //     outline: 2px solid orangered;
            // }
        }
        li {
            &:nth-child(1) {
                @include tablet {
                    margin-left: 20%;
                }
                // @include desktop {
                //     margin-left: 32%;
                // }
                img {
                    @include mobile {
                        left: 2.25rem;
                        top: -0.5rem;
                    }
                    @include tablet {
                        left: 2.65rem;
                        top: -0.5rem;
                    }
                    @include desktop {
                        left: 3rem;
                        top: -0.5rem;
                    }
                }
            }
            &:nth-child(2) {
                @include tablet {
                    margin-left: 5%;
                }
                // @include desktop {
                //     margin-left: 55%;
                // }
                img {
                    @include mobile {
                        left: 2.5rem;
                        top: 0;
                    }
                    @include tablet {
                        left: 3rem;
                        top: 0.25rem;
                    }
                    @include desktop {
                        left: 3.5rem;
                        top: 0.5rem;
                    }
                }
            }
            &:nth-child(3) {
                @include tablet {
                    margin-left: 20%;
                }
                // @include desktop {
                //     margin-left: 66%;
                // }
                img {
                    @include mobile {
                        left: 2.25rem;
                        top: 0.125rem;
                    }
                    @include tablet {
                        left: 2.8rem;
                        top: 0.25rem;
                    }
                    @include desktop {
                        left: 3.25rem;
                        top: 0.5rem;
                    }
                }
            }
            &:nth-child(4) {
                @include tablet {
                    margin-left: 40%;
                }
                // @include desktop {
                //     margin-left: 71%;
                // }
                img {
                    @include mobile {
                        left: 2.25rem;
                        top: 0;
                    }
                    @include tablet {
                        left: 2.75rem;
                        top: 0.5rem;
                    }
                    @include desktop {
                        left: 3.25rem;
                        top: 0.5rem;
                    }
                }
            }
        }
    }
}
