.slider-home-container {
    overflow-y: hidden;
    @include mobile {
        height: 41.625rem;
    }
    @include tablet {
        height: 34.375rem;
    }
    @include widescreen {
        height: 54.8125rem;
    }
    + div.wrapper {
        /*perquè funcioni el link "Anar a donacions"*/
        position: relative;
        z-index: 1;
    }
}
.slider {
    &--home {
        h2 {
            color: #000;
            // font-family: helvetica_neuebold, $family-primary;
            position: relative;
        }
        img,
        video {
            object-fit: cover;
            width: 100%;
        }
        video {
            height: 100%;
        }
        &-mobile {
            h2 {
                bottom: -0.5em;
            }
            img {
                height: 25rem;
            }
        }
        &-desktop {
            margin-bottom: 0 !important;
            .js & {
                height: 54.8125rem;
            }
            .columns {
                @include widescreen {
                    margin-bottom: 0 !important;
                }
            }
            h2 {
                @include desktop {
                    font-size: var(--step-5);
                    bottom: -0.5em;
                }
                @include widescreen {
                    font-size: var(--step-7);
                    line-height: 0.9;
                    hyphens: auto;
                }
            }
            img {
                height: 38rem;
            }
        }
    }
    // Navegació slider home
    &-nav {
        .slick-arrow,
        .slick-dots {
            position: static;
            transform: none;
        }
        .slick-dots {
            display: flex;
            gap: var(--space-2xs);
            li {
                margin: 0;
                padding: 0;
                button {
                    width: 2rem;
                    height: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                    padding: 0;
                    border-radius: 50%;
                    &::before {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        background-color: #fff;
                        border-radius: 50%;
                    }
                    span {
                        font-size: 0;
                    }
                }
                &.slick-active button {
                    background-color: #fff;
                    &::before {
                        display: none;
                    }
                    span {
                        font-size: var(--step--1) !important;
                        color: #000 !important;
                        font-weight: 700;
                        // font-family: helvetica_neuebold, $family-primary;
                    }
                }
            }
        }
        &--desktop {
            @include desktop {
                position: absolute;
                left: calc(50% + $column-gap);
                top: 8rem;
                z-index: 1;
            }
            @include widescreen {
                left: calc(58.33333337% + $column-gap);
                top: 12rem;
            }
        }
        .slick-arrow span::before {
            content: "";
            width: 18px;
            height: 18px;
            display: block;
        }
        .slick-prev-icon::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18.216'%3E%3Cg fill='none' stroke='%23ab192d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M17 9.109H1'/%3E%3Cpath d='M7.373 1.408 1 9.108l6.373 7.7'/%3E%3C/g%3E%3C/svg%3E");
        }
        .slick-next-icon::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18.216'%3E%3Cg fill='none' stroke='%23ab192d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 9.109h16'/%3E%3Cpath d='M10.627 1.408 17 9.108l-6.373 7.7'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}
//ajustar títol per slider

.slider--home .is-7-widescreen {
	@include desktop {
		min-height: 190px;
	}
	@include widescreen {
		min-height: 263px;
	}
}
