.collapsible {
    .js & {
        border-top: 1px solid $primary;
        .collapsible__title {
            font-size: var(--step-1);
            text-transform: none;
            font-family: $family-secondary;
            font-weight: normal;
            margin-top: 0;
            button {
                all: inherit;
                border: 0;
                width: 100%;
                border-bottom: 1px solid $primary;
                margin-top: 0;
                &:hover,
                &:active {
                    background-color: inherit;
                }
                &[aria-expanded="true"] {
                    border-bottom: 0;
                }
                &:focus {
                    @include focus;
                }
            }
            + div {
                margin-top: 0 !important;
                padding: 0 var(--space-2xl-3xl) var(--space-m) var(--space-s);
                &:not(hidden) {
                    border-bottom: 1px solid $primary;
                }
                > * {
                    font-size: var(--step--1);
                    + * {
                        margin-top: var(--space-s);
                    }
                }
            }
        }
    }
    button svg {
        display: initial;
        width: 45px;
        height: 45px;
    }
    [aria-expanded="true"] svg {
        transform: rotate(0.5turn);
        margin-right: 0;
        margin-left: var(--step-6);
    }
}
