// Importa els helpers de Bulma
@import "../bulma-customize/helpers/spacing";

// Defineix els breakpoints per a mòbil, tablet i escriptori
$breakpoints: (
  "mobile": null,
  "tablet": 769px,
  "desktop": 1024px,
  "widescreen": 1216px,
);

// Defineix només els tipus de marge i padding que necessitis
$spacing-types: (
  "m-": "margin",
  "mt-": "margin-top",
  "mb-": "margin-bottom",
  "ml-": "margin-left",
  "mr-": "margin-right",
  "p-": "padding",
  "pt-": "padding-top",
  "pb-": "padding-bottom",
  "pl-": "padding-left",
  "pr-": "padding-right",
);

// Genera només les classes necessàries
@each $size-name, $size-value in $spacing-values {
  // Genera classes per a mòbil (sense media query)
  @each $type-abbr, $type-property in $spacing-types {
    .#{$type-abbr}#{$size-name}-mobile {
      #{$type-property}: $size-value ;
    }
  }

  // Genera classes per a tablet i desktop dins de @media queries
  @each $bp, $bp-size in $breakpoints {
    @if $bp-size != null {
      @media (min-width: $bp-size) {
        @each $type-abbr, $type-property in $spacing-types {
          .#{$type-abbr}#{$size-name}-#{$bp} {
            #{$type-property}: $size-value !important;
          }
        }
      }
    }
  }
}